import React, { useEffect, useState } from "react";
import request from "@/service";
import { Table, Tag } from "antd";
import SendPanel from "@/components/SendPanel";
import RecvPanel from "@/components/RecvPanel";
import FreightPanel from "@/components/FreightPanel";
import OrderNoPanel from "@/components/OrderNoPanel";
import OrderPackPanel from "@/components/OrderPackPanel";
import _ from "lodash";

const Index: React.FC<any> = () => {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const columns = [
    {
      title: "单号",
      dataIndex: "orderNo",
      key: "orderNo",
      ellipsis: true,
      width: 160,
      render: (_: any, record: any) => <OrderNoPanel hyOrder={record} />,
    },
    {
      title: "始发站",
      dataIndex: "firstStoreName",
      key: "firstStoreName",
      width: 180,
      render: (_: any, record: any) => <OrderPackPanel hyOrder={record} />,
    },
    {
      title: "寄件人",
      dataIndex: "sendUserName",
      key: "sendUserName",
      render: (_: any, record: any) => <SendPanel hyOrder={record} />,
    },
    {
      title: "收件人",
      dataIndex: "rcvUserName",
      key: "rcvUserName",
      render: (_: any, record: any) => <RecvPanel hyOrder={record} />,
    },
    {
      title: "运费",
      dataIndex: "freight",
      width: 120,
      key: "freight",
      render: (_: any, record: any) => <FreightPanel hyOrder={record} />,
    },
  ];
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/freightTx/list",
        params: {
          ...pager,
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  return (
    <div className="container">
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          current: pager.pageNo,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
    </div>
  );
};

export default Index;
