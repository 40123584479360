import { Table, Button, Spin, Space, Popconfirm, message } from "antd";
import React, { Fragment, lazy, Suspense, useEffect, useState } from "react";
import request from "@/service";
import SingleSearch from "@/components/SingleSearch";
import MyDelFlag from "@/components/MyDelFlag";
import {
  FormOutlined,
  CalendarOutlined,
  TeamOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import qs from "qs";

const AddSite = lazy(() => import("./AddSite"));
const EditSite = lazy(() => import("./EditSite"));
const SiteTime = lazy(() => import("./SiteTime"));
const SiteUser = lazy(() => import("./SiteUser"));

const HySite = (props: any) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showSiteTime, setShowSiteTime] = useState(false);
  const [showSiteUser, setShowSiteUser] = useState(false);
  const [curId, setCurId] = useState("");
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [custSiteLoading, setCustSiteLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  let _keyWords = "";
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const refresh = () => {
    setShowAdd(false);
    setShowEdit(false);
    loadData();
  };
  const columns = [
    {
      title: "站点名称",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: 160,
    },
    {
      title: "省市区",
      dataIndex: "area",
      key: "area",
      ellipsis: true,
      render: (_: any, record: any) => (
        <Space size="middle">
          <span>
            {record.provinceName}
            {record.cityName}
            {record.areaName}
          </span>
        </Space>
      ),
    },
    {
      title: "联系电话",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "具体地址",
      dataIndex: "address",
      key: "address",
      ellipsis: true,
    },
    {
      title: "服务范围",
      dataIndex: "serviceTxt",
      key: "serviceTxt",
      ellipsis: true,
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
      ellipsis: true,
    },
    {
      title: "客户自选线路",
      dataIndex: "custSiteFlag",
      key: "gxFlcustSiteFlagag",
      render: (_: any, record: any) =>
        record.custSiteFlag === "1" ? (
          <Popconfirm
            title="确定要取消客户自选线路吗？"
            placement="topRight"
            onConfirm={() => submitCustSiteFlag(record.id)}
          >
            <Button type="primary" size="small">
              是
            </Button>
          </Popconfirm>
        ) : (
          <Popconfirm
            title="确定设置为客户自选线路吗？"
            placement="topRight"
            onConfirm={() => submitCustSiteFlag(record.id)}
          >
            <Button type="primary" danger size="small">
              否
            </Button>
          </Popconfirm>
        ),
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      render: (_: any, record: any) => (
        <Space size="small">
          <Button
            type="primary"
            size="small"
            title="编辑"
            icon={<FormOutlined />}
            onClick={() => clickEdit(record.id)}
          />
          <Button
            type="primary"
            title="发车时间"
            size="small"
            icon={<CalendarOutlined />}
            onClick={() => {
              setCurId(record.id);
              setShowSiteTime(true);
            }}
          />
          <Button
            type="primary"
            title="站点员工"
            size="small"
            onClick={() => {
              setCurId(record.id);
              setShowSiteUser(true);
            }}
            icon={<TeamOutlined />}
          />
          <Popconfirm
            title="确定要删除站点吗？"
            placement="topRight"
            onConfirm={() => deleteSite(record.id)}
          >
            <Button
              type="primary"
              danger
              size="small"
              title="删除"
              loading={delLoading}
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const clickEdit = (id: any) => {
    setCurId(id);
    setShowEdit(true);
  };
  const doSearch = (keyWords: string) => {
    _keyWords = keyWords;
    loadData();
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/hySite/list",
        params: {
          ...pager,
          keyWords: _keyWords,
        },
      });
      if (res?.rows) {
        res?.rows.map((item: any, index: number) => {
          item.myDelFlag = (
            <Fragment key={item.id}>
              <MyDelFlag delFlag={item.delFlag} />
            </Fragment>
          );
        });
      }
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  const deleteSite = async (id: string) => {
    try {
      setDelLoading(true);
      const res: any = await request.post({
        url: "/hy/hySite/deleteStore",
        data: qs.stringify({
          id,
        }),
      });
      message.success("删除成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
    setDelLoading(false);
  };
  const submitCustSiteFlag = async (id: string) => {
    try {
      setCustSiteLoading(true);
      const res: any = await request.post({
        url: "/hy/hyOrgStore/custSiteFlag",
        data: qs.stringify({
          id,
        }),
      });
      message.success("提交成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
    setCustSiteLoading(false);
  };
  return (
    <div className="container">
      <div className="flex mb-1.5">
        <SingleSearch placeholder="站点名称" doSearch={doSearch} />
        <Button type="primary" onClick={() => setShowAdd(true)}>
          新建
        </Button>
      </div>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <AddSite
          open={showAdd}
          refresh={refresh}
          onCancel={() => {
            setShowAdd(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <EditSite
          siteId={curId}
          open={showEdit}
          refresh={refresh}
          onCancel={() => {
            setShowEdit(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <SiteTime
          siteId={curId}
          open={showSiteTime}
          onCancel={() => {
            setShowSiteTime(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <SiteUser
          siteId={curId}
          open={showSiteUser}
          onCancel={() => {
            setShowSiteUser(false);
          }}
        />
      </Suspense>
    </div>
  );
};
export default HySite;
