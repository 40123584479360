import { Modal, message, Table, Space, Popconfirm, Button, Spin } from "antd";
import React, { Suspense, useEffect, useState, lazy } from "react";
import request from "@/service";
import qs from "qs";
import { DeleteOutlined, PlusOutlined, FormOutlined } from "@ant-design/icons";

const HyCancelAdd = lazy(() => import("./HyCancelAdd"));

const Index: React.FC = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const refresh = () => {
    setShowAdd(false);
    loadData();
  };
  const columns = [
    {
      title: "省份名称",
      dataIndex: "provinceName",
      key: "provinceName",
    },
    {
      title: "市名称",
      dataIndex: "cityName",
      key: "cityName",
    },
    {
      title: "时长(分钟)",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: (
        <Button
          type="primary"
          size="small"
          icon={<PlusOutlined />}
          onClick={() => {
            setShowAdd(true);
          }}
          className="search-btn-bg"
        >
          新建
        </Button>
      ),
      dataIndex: "id",
      key: "id",
      render: (_: any, record: any) => (
        <Popconfirm
          title="确定要删除吗？"
          placement="topRight"
          onConfirm={() => deleteCancelTime(record.id)}
        >
          <Button
            type="primary"
            danger
            size="small"
            title="删除"
            loading={delLoading}
            icon={<DeleteOutlined />}
          />
        </Popconfirm>
      ),
    },
  ];
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/hyCancel/list",
        params: {
          ...pager,
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  const deleteCancelTime = async (id: string) => {
    try {
      setDelLoading(true);
      const res: any = await request.post({
        url: "/hy/hyCancel/remove",
        data: qs.stringify({
          id,
        }),
      });
      message.success("删除成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
    setDelLoading(false);
  };
  return (
    <div className="container">
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <HyCancelAdd
          open={showAdd}
          refresh={refresh}
          onCancel={() => {
            setShowAdd(false);
          }}
        />
      </Suspense>
    </div>
  );
};

export default Index;
