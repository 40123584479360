import { Tabs } from "antd";
import React from "react";
import CustSettlement from "./CustSettlement";
import CustNoSettlement from "./CustNoSettlement";
import CustApplySettlement from "./CustApplySettlement";

const Index = () => {
  const items = [
    { label: "未结算", key: "1", children: <CustNoSettlement /> }, // 务必填写 key
    { label: "申请中", key: "2", children: <CustApplySettlement /> },
    { label: "已结算", key: "3", children: <CustSettlement /> },
  ];
  return (
    <div className="container h-full">
      <div className="bg-white">
        <Tabs items={items} defaultActiveKey="1" type="card" />
      </div>
    </div>
  );
};
export default Index;
