import request from "@/service";
import {
  DatabaseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, message, Popconfirm, Space, Spin, Table, Tag } from "antd";
import qs from "qs";
import _ from "lodash";
import React, { Suspense, useEffect, useState, lazy } from "react";

const AddRole = lazy(() => import("./AddRole"));
const EditRole = lazy(() => import("./EditRole"));
const RoleMenu = lazy(() => import("./RoleMenu"));

const Index = () => {
  const [curItem, setCurItem] = useState<any>({});
  const [showPermission, setShowPermission] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const columns = [
    {
      title: "角色名",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "权限",
      dataIndex: "sign",
      key: "sign",
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      render: (_: any, record: any) => (
        <Space>
          <Button
            type="primary"
            size="small"
            icon={<DatabaseOutlined />}
            onClick={() => {
              setCurItem(record);
              setShowPermission(true);
            }}
          />
          <Button
            type="primary"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              setCurItem(record);
              setShowEdit(true);
            }}
          />
          <Popconfirm
            placement="topRight"
            title="确定要删除角色吗？"
            onConfirm={() => {
              deleteRole(record.id);
            }}
            okText="确定"
            cancelText="取消"
          >
            <Button
              type="primary"
              size="small"
              danger
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const refresh = () => {
    setShowAdd(false);
    setShowEdit(false);
    loadData();
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/sys/role/list2",
        params: {
          ...pager,
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  const deleteRole = async (id: string) => {
    try {
      const res: any = await request.post({
        url: "/common/log/remove",
        data: qs.stringify({
          id,
        }),
      });
      message.success("删除成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
  };
  return (
    <div className="container">
      <div className="mb-1.5">
        <Button type="primary" onClick={() => setShowAdd(true)}>
          添加角色
        </Button>
      </div>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <AddRole
          open={showAdd}
          refresh={refresh}
          onCancel={() => {
            setShowAdd(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <EditRole
          roleId={curItem?.id}
          remark={curItem?.remark}
          name={curItem?.name}
          sign={curItem?.sign}
          open={showEdit}
          refresh={refresh}
          onCancel={() => {
            setShowEdit(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <RoleMenu
          roleId={curItem?.id}
          sign={curItem?.sign}
          open={showPermission}
          onCancel={() => {
            setShowPermission(false);
          }}
        />
      </Suspense>
    </div>
  );
};

export default Index;
