import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import "./less/SingleSearch.less";

interface Values {
  keyWords: string;
}
interface Props {
  defVal?: string;
  placeholder?: string;
  doSearch: (keyWords: string) => void;
}

const SingleSearch: React.FC<Props> = ({ defVal, placeholder, doSearch }) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (defVal) {
      form.setFieldsValue({
        keyWords: defVal,
      });
    }
  }, []);

  const onFinish = (values: Values) => {
    doSearch(values.keyWords ?? "");
  };

  return (
    <Form
      form={form}
      layout="inline"
      onFinish={onFinish}
      initialValues={{ keyWords: "" }}
    >
      <Form.Item name="keyWords">
        <Input allowClear placeholder={placeholder ?? "名称搜索"} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="search-btn-bg">
          查询
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SingleSearch;
