import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Table,
  DatePicker,
} from "antd";
import React, { Suspense, useEffect, useState, lazy } from "react";
import request from "@/service";
import SendPanel from "@/components/SendPanel";
import RecvPanel from "@/components/RecvPanel";
import CustFreightPanel from "@/components/CustFreightPanel";
import CustHkPanel from "@/components/CustHkPanel";
import PtPanel from "@/components/PtPanel";
import CustOrderNoPanel from "@/components/CustOrderNoPanel";
import CustOrderPackPanel from "@/components/CustOrderPackPanel";
import _ from "lodash";

const HyOrderFlow = lazy(() => import("@/pages/hyOrderFlow"));

const { Option } = Select;
const { RangePicker } = DatePicker;

const Index = () => {
  const [showFlow, setShowFlow] = useState(false);
  const [curId, setCurId] = useState("");
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [searParams, setSearParams] = useState({});
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  useEffect(() => {
    loadData();
  }, [pager]);
  const refresh = () => {
    loadData();
  };
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const onFinish = (values: any) => {
    const _params: any = values;
    const rangeTime = values["rangeTime"];
    if (rangeTime && rangeTime.length === 2) {
      _params["startTime"] =
        rangeTime[0].format("YYYY-MM-DD").toString() + " 00:00:00";
      _params["endTime"] =
        rangeTime[1].format("YYYY-MM-DD").toString() + " 23:59:59";
    }
    _params["rangeTime"] = "";
    const tmpPager = JSON.parse(JSON.stringify(pager));
    tmpPager.pageNo = 1;
    setSearParams(_params);
    setPager(tmpPager);
  };
  const clickOrderNo = (orderId: string) => {
    setCurId(orderId);
    setShowFlow(true);
  };
  const columns = [
    {
      title: "单号",
      dataIndex: "orderNo",
      key: "orderNo",
      ellipsis: true,
      width: 160,
      render: (_: any, record: any) => (
        <CustOrderNoPanel
          hyOrder={record}
          clickOrderNo={() => clickOrderNo(record.id)}
        />
      ),
    },
    {
      title: "始发站",
      dataIndex: "firstStoreName",
      key: "firstStoreName",
      width: 120,
      render: (_: any, record: any) => <CustOrderPackPanel hyOrder={record} />,
    },
    {
      title: "寄件人",
      dataIndex: "sendUserName",
      key: "sendUserName",
      render: (_: any, record: any) => <SendPanel hyOrder={record} />,
    },
    {
      title: "收件人",
      dataIndex: "rcvUserName",
      key: "rcvUserName",
      render: (_: any, record: any) => <RecvPanel hyOrder={record} />,
    },
    {
      title: "运费",
      dataIndex: "freight",
      key: "freight",
      render: (_: any, record: any) => <CustFreightPanel hyOrder={record} />,
    },
    {
      title: "代收货款",
      dataIndex: "productMoney",
      key: "productMoney",
      render: (_: any, record: any) => <CustHkPanel hyOrder={record} />,
    },
    {
      title: "跑腿费",
      dataIndex: "ptPrice",
      key: "ptPrice",
      render: (_: any, record: any) => <PtPanel hyOrder={record} />,
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
      width: 100,
    },
  ];
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/custOrder/list",
        params: {
          ...pager,
          type: "2",
          ...searParams,
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  return (
    <div className="container">
      <Form
        name="horizontal_login"
        layout="inline"
        onFinish={onFinish}
        initialValues={{
          status: "",
          freightPayType: "",
          freightType: "",
          settle: "",
          keyWords: "",
          rangeTime: [],
        }}
        style={{ marginBottom: "8px" }}
      >
        <Form.Item name="status" label="">
          <Select placeholder="订单状态">
            <Option value="">订单状态</Option>
            <Option value="0">已取消</Option>
            <Option value="1">客户下单</Option>
            <Option value="2">待取件</Option>
            <Option value="3">已取件</Option>
            <Option value="4">已到站点</Option>
            <Option value="5">运输中</Option>
            <Option value="6">待派送</Option>
            <Option value="7">派送中</Option>
            <Option value="8">已签收</Option>
            <Option value="9">作废</Option>
            <Option value="10">跑腿中</Option>
          </Select>
        </Form.Item>
        <Form.Item name="freightPayType" label="">
          <Select placeholder="运费状态">
            <Option value="">运费状态</Option>
            <Option value="on">线上收款</Option>
            <Option value="off">线下全部</Option>
            <Option value="1">线下用劵</Option>
            <Option value="2">线下月结</Option>
            <Option value="offMoney">线下现金</Option>
          </Select>
        </Form.Item>
        <Form.Item name="freightType" label="">
          <Select placeholder="运费付款方">
            <Option value="">运费付款方</Option>
            <Option value="1">寄方付</Option>
            <Option value="2">收方付</Option>
          </Select>
        </Form.Item>
        <Form.Item name="settle" label="">
          <Select placeholder="货款状态">
            <Option value="">货款状态</Option>
            <Option value="7">待支付</Option>
            <Option value="1">线下未结算</Option>
            <Option value="2">线下已结算</Option>
            <Option value="3">线上未结算</Option>
            <Option value="4">线上已结算</Option>
          </Select>
        </Form.Item>
        <Form.Item name="rangeTime">
          <RangePicker format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item name="keyWords">
          <Input placeholder="单号、姓名、电话等" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="search-btn-bg">
            查询
          </Button>
        </Form.Item>
      </Form>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          current: pager.pageNo,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <HyOrderFlow
          orderId={curId}
          open={showFlow}
          onCancel={() => {
            setShowFlow(false);
          }}
        />
      </Suspense>
    </div>
  );
};
export default Index;
