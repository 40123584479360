import React from "react";
import "./less/NotFound.less";
import PlaneImg from "../assets/imgs/plane.png";
import _4Img from "../assets/imgs/4.png";
import _404Img from "../assets/imgs/404_bg.png";
import { Button } from "antd";
import { useLocation } from "react-router-dom";

const Index: React.FC = () => {
  const location = useLocation();
  console.log("location-->", location);

  return (
    <div className="text-center h-screen bg-white">
      <div className="not-found">
        <img className="plane-img" src={PlaneImg} />
        <div className="content">
          <div className="f-imgs">
            <img className="f1-img" src={_4Img} />
            <img className="f0f-bg-img" src={_404Img} />
            <img className="f2-img" src={_4Img} />
          </div>
          <div className="bottom">
            <div className="notice">
              <span className="text">ERROR</span>
              <span className="text ml-6 mr-6">
                对不起，您访问的页面不存在！
              </span>
              <span className="text">点击返回首页，或者刷新页面。</span>
            </div>
            <div className="btns">
              <Button
                shape="round"
                type="primary"
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                返回首页
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Index;
