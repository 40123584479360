import request from "@/service";
import {
  DeleteOutlined,
  EditOutlined,
  FormOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button, message, Popconfirm, Space, Spin, Table, Tag } from "antd";
import qs from "qs";
import _ from "lodash";
import React, { Suspense, useEffect, useState, lazy } from "react";

const AddMenu = lazy(() => import("./AddMenu"));
const EditMenu = lazy(() => import("./EditMenu"));

interface DataType {
  id: string;
  permission: string;
  name: string;
  orderNum: number;
  type: number;
  url: string;
  perms: string;
  children?: DataType[];
}
const HyAddress = () => {
  const [curItem, setCurItem] = useState<any>({});
  const [curPid, setCurPid] = useState("0");
  const [curPname, setCurPname] = useState("0");
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<DataType[]>([]);
  useEffect(() => {
    loadData();
  }, []);
  const columns = [
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "排序",
      dataIndex: "orderNum",
      key: "orderNum",
    },
    {
      title: "类型",
      dataIndex: "type",
      key: "type",
      render: (_: any, record: any) =>
        record.type === 0 ? (
          <Tag color="blue">目录</Tag>
        ) : record.type === 1 ? (
          <Tag color="green">菜单</Tag>
        ) : record.type === 2 ? (
          <Tag color="orange">按钮</Tag>
        ) : (
          "-"
        ),
    },
    {
      title: "权限分组",
      dataIndex: "permission",
      key: "permission",
      render: (_: any, record: any) =>
        record.permission === "0" ? (
          <Tag color="gold">全部</Tag>
        ) : record.permission === "1" ? (
          <Tag color="red">客服</Tag>
        ) : record.permission === "2" ? (
          <Tag color="blue">物流</Tag>
        ) : record.permission === "3" ? (
          <Tag color="green">自寄客户</Tag>
        ) : (
          "-"
        ),
    },
    {
      title: "地址",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "权限标识",
      dataIndex: "perms",
      key: "perms",
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      render: (_: any, record: any) => (
        <Space size="small">
          <Button
            type="primary"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              setCurItem(record);
              setShowEdit(true);
            }}
          />
          {record.type === 0 && (
            <Button
              type="primary"
              size="small"
              icon={<PlusOutlined />}
              onClick={() => {
                if (record.type === 0) {
                  setCurPid(record.id);
                  setCurPname(record.name);
                  setShowAdd(true);
                } else {
                  message.error("抱歉，只目录才能添加子菜单！");
                }
              }}
            />
          )}
          <Popconfirm
            placement="topRight"
            title="确定删除菜单吗？"
            onConfirm={() => {
              deleteMenu(record.id);
            }}
            okText="确定"
            cancelText="取消"
          >
            <Button
              type="primary"
              size="small"
              icon={<DeleteOutlined />}
              danger
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const refresh = () => {
    setShowAdd(false);
    setShowEdit(false);
    loadData();
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/sys/menu/list2",
        params: {},
      });
      const arr: DataType[] = [];
      if (res?.result && res?.result?.length > 0) {
        res?.result.forEach((item: any) => {
          if (item.pid === "" || item.pid === "0") {
            const Menu: DataType = {
              id: item.id,
              permission: item.permission,
              name: item.name,
              orderNum: item.orderNum,
              type: item.type,
              url: item.url,
              perms: item.perms,
              children: [],
            };
            arr.push(Menu);
          }
        });
        if (arr && arr.length > 0) {
          arr.forEach((parentMenu: any) => {
            res?.result.forEach((item: any) => {
              if (
                item.pid !== "" &&
                item.pid !== "0" &&
                item.pid === parentMenu.id
              ) {
                const Menu: DataType = {
                  id: item.id,
                  permission: item.permission,
                  name: item.name,
                  orderNum: item.orderNum,
                  type: item.type,
                  url: item.url,
                  perms: item.perms,
                };
                parentMenu.children.push(Menu);
              }
            });
          });
        }
      }
      if (arr && arr.length > 0) {
        arr.forEach((menu: any) => {
          if (menu.children.length <= 0) {
            delete menu.children;
          }
        });
      }
      setTableData(arr);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  const deleteMenu = async (id: string) => {
    try {
      const res: any = await request.post({
        url: "/sys/menu/remove",
        data: qs.stringify({
          id,
        }),
      });
      message.success("删除成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
  };
  return (
    <div className="container">
      <div className="flex mb-2">
        <Space size="small">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setCurPid("0");
              setCurPname("根目录");
              setShowAdd(true);
            }}
          >
            新建菜单
          </Button>
          <Button
            type="primary"
            icon={<ReloadOutlined />}
            onClick={loadData}
            loading={loading}
          >
            刷新
          </Button>
        </Space>
      </div>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        pagination={false}
        bordered
        rowKey="id"
        dataSource={tableData}
        columns={columns}
      />
      <Suspense fallback={<Spin />}>
        <EditMenu
          menuId={curItem.id}
          permission={curItem.permission}
          name={curItem.name}
          orderNum={curItem.orderNum}
          type={curItem.type}
          url={curItem.url}
          perms={curItem.perms}
          open={showEdit}
          refresh={refresh}
          onCancel={() => {
            setShowEdit(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <AddMenu
          pid={curPid}
          pname={curPname}
          open={showAdd}
          refresh={refresh}
          onCancel={() => {
            setShowAdd(false);
          }}
        />
      </Suspense>
    </div>
  );
};

export default HyAddress;
