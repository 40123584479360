import { Spin } from "antd";
import React from "react";

const Index: React.FC = () => {
  return (
    <div className="h-full w-full bg-white" style={{ paddingTop: "20%" }}>
      <div className="text-center">
        <Spin size="large"></Spin>
      </div>
      <div className="text-center text-gray-600 mt-5">加载中...</div>
    </div>
  );
};
export default Index;
