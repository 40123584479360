import request from "@/service";
import { Button, Form, Input, Select, Table, Tag } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";

const { Option } = Select;

const Index = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({});
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const columns = [
    {
      title: "商户",
      dataIndex: "userOrgName",
      key: "userOrgName",
    },
    {
      title: "姓名",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "账户",
      dataIndex: "userCode",
      key: "userCode",
    },
    {
      title: "用户类型",
      dataIndex: "userType",
      key: "userType",
      width: 100,
      render: (_: any, record: any) => (
        <>
          {record.userType === "0" && <Tag>物流员工</Tag>}
          {record.userType === "1" && <Tag>自寄子账户</Tag>}
          {record.userType === "2" && <Tag>物流主账户</Tag>}
          {record.userType === "3" && <Tag>客服</Tag>}
          {record.userType === "4" && <Tag>自寄主账户</Tag>}
          {record.userType === "5" && <Tag>ADMIN</Tag>}
        </>
      ),
    },
    {
      title: "登录方式",
      dataIndex: "loginType",
      key: "loginType",
      width: 100,
      render: (_: any, record: any) => (
        <>
          {record.loginType === "1" && <Tag>浏览器</Tag>}
          {record.loginType === "2" && <Tag>微信小程序</Tag>}
          {record.loginType === "3" && <Tag>Android</Tag>}
          {record.loginType === "4" && <Tag>IOS</Tag>}
        </>
      ),
    },
    {
      title: "登录地址",
      dataIndex: "loginIp",
      key: "loginIp",
    },
    {
      title: "登录时间",
      dataIndex: "loginTime",
      key: "loginTime",
    },
  ];
  const onFinish = (values: any) => {
    setParams(values);
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/loginLogs/list",
        params: {
          ...pager,
          ...setParams,
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  return (
    <div className="container">
      <Form
        form={form}
        layout="inline"
        onFinish={onFinish}
        initialValues={{
          keyWords: "",
          loginType: "",
          userType: "",
        }}
      >
        <Form.Item name="loginType" label="">
          <Select placeholder="登录方式">
            <Option value="">登录方式</Option>
            <Option value="1">浏览器</Option>
            <Option value="2">微信小程序</Option>
            <Option value="3">Android</Option>
            <Option value="4">IOS</Option>
          </Select>
        </Form.Item>
        <Form.Item name="userType" label="">
          <Select placeholder="用户类型">
            <Option value="">用户类型</Option>
            <Option value="0">物流员工</Option>
            <Option value="1">自寄子账户</Option>
            <Option value="2">物流主账户</Option>
            <Option value="3">客服</Option>
            <Option value="4">自寄主账户</Option>
          </Select>
        </Form.Item>
        <Form.Item name="keyWords">
          <Input allowClear placeholder="姓名、电话等" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="search-btn-bg">
            查询
          </Button>
        </Form.Item>
      </Form>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
    </div>
  );
};

export default Index;
