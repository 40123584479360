import React, { ReactNode } from "react";
import _ from "lodash";
import { Button, Popconfirm, Tag, TagType } from "antd";

const Index: React.FC<any> = (props: any) => {
  return (
    <div>
      {props.hyOrder.productMoneyType === "1" ? (
        <span>-</span>
      ) : (
        <div>
          <span className="text-price text-red-500">
            {props.hyOrder.productMoney}
          </span>
          {props.hyOrder.ptType !== "1" && (
            <div>{getStatusName(props.hyOrder)}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default Index;

const getStatusName = (hyOrder: any): ReactNode => {
  if (hyOrder.payStatus === "1") {
    return <Tag color="red">待支付</Tag>;
  }
  let str = "";
  if (hyOrder.payStatus === "2") {
    if (hyOrder.jsStatus == "1") {
      str = "线下未结算";
    } else if (hyOrder.jsStatus == "2") {
      str = "线下结算中";
    } else if (hyOrder.jsStatus == "3") {
      str = "线下已结算";
    } else if (hyOrder.jsStatus == "4") {
      str = "线下申请结算";
    } else {
      str = "线下支付";
    }
    return <Tag color="green">{str}</Tag>;
  }
  if (hyOrder.payStatus === "3") {
    if (hyOrder.jsStatus == "1") {
      str = "线上未结算";
    } else if (hyOrder.jsStatus == "2") {
      str = "线上结算中";
    } else if (hyOrder.jsStatus == "3") {
      str = "线上已结算";
    } else {
      str = "线上支付";
    }
  }
  return _.isEmpty(str) ? "" : <Tag color="blue">{str}</Tag>;
};
