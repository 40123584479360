import React, { useState } from "react";
import { Button, Form, Input, message, Spin } from "antd";
import { UserOutlined, KeyOutlined, LoginOutlined } from "@ant-design/icons";
import "./less/Login.less";
import request from "@/service";
import qs from "qs";
import storageUtil from "@/utils/storage";
import { useNavigate } from "react-router-dom";
import chhy_app from "../assets/imgs/chhy_app.png";
import miniprogram from "../assets/imgs/miniprogram.jpg";
import wxopen from "../assets/imgs/wxopen.jpg";
import loginPic from "../assets/imgs/login_pic_2x.png";

const Login: React.FC = () => {
  console.log(window.screen);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const savePermissionMenuPath = (menus: []) => {
    const pathArr: string[] = [];
    menus.forEach((item: any) => {
      if (item.hasChildren) {
        item.children.forEach((child: any) => {
          const pathname = getMenuKey(child.attributes.url);
          pathArr.push("/" + pathname);
        });
      } else {
        const pathname = getMenuKey(item.attributes.url);
        pathArr.push("/" + pathname);
      }
    });
    storageUtil.setItem("pathArr", pathArr.join(","));
  };
  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const res: any = await request.post({
        url: "/doLogin",
        data: qs.stringify({ ...values }),
        showLoading: true,
      });
      message.success("登录成功！");
      const result = res.result;
      storageUtil.setItem("hyOrg", result.hyOrg);
      storageUtil.setItem("sysDept", result.sysDept);
      storageUtil.setItem("userId", result.userId);
      storageUtil.setItem("userName", result.userName);
      storageUtil.setItem("rootDeptName", result.rootDeptName);
      storageUtil.setItem("userType", result.userType);
      storageUtil.setItem("roleName", result.roleName);
      storageUtil.setItem("menus", result.menus);
      savePermissionMenuPath(result.menus);
      setTimeout(() => {
        navigate("/");
      }, 500);
    } catch (error: any) {
      console.log("error-->", error);
    }
    setLoading(false);
  };
  return (
    <div className="login-container h-screen w-screen">
      <div className="left h-screen">
        <div className="content h-screen">
          <img src={loginPic} alt="" />
        </div>
      </div>
      <div className="right h-screen">
        <div className="content h-screen"></div>
      </div>
      <div className="form-wrapper">
        <div className="content">
          <div className="form-box">
            <Spin spinning={loading} size="large">
              <div className="title">欢迎使用快马货运</div>
              <Form name="basic" onFinish={onFinish} autoComplete="off">
                <Form.Item
                  label=""
                  name="username"
                  rules={[{ required: true, message: "请输入账户名!" }]}
                >
                  <Input
                    prefix={<UserOutlined />}
                    size="large"
                    className="rounded-2xl"
                    placeholder="请输入账户名"
                    allowClear
                    autoComplete="username"
                  />
                </Form.Item>
                <Form.Item
                  label=""
                  name="password"
                  rules={[{ required: true, message: "请输入密码!" }]}
                >
                  <Input.Password
                    prefix={<KeyOutlined />}
                    size="large"
                    placeholder="请输入密码"
                    allowClear
                    autoComplete="current-password"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    icon={<LoginOutlined />}
                    shape="round"
                    block
                    size="large"
                    type="primary"
                    htmlType="submit"
                  >
                    立即登录
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
            <div className="footer">
              <div className="qrcode">
                <img src={miniprogram} alt="" />
                <img src={wxopen} alt="" />
                <img src={chhy_app} alt="" />
              </div>
            </div>
          </div>
          <div className="bg-2"></div>
          <div className="bg-3"></div>
        </div>
      </div>
      <div className="bottom">
        <span>&copy; 2021 All Rights Reserved </span>
        <a href="http://www.chehego.com/" target="_blank" rel="noreferrer">
          车和<sup>®</sup>科技
        </a>
        <span className="ml-2">备案编号：</span>
        <a href="https://beian.miit.gov.cn" target="_blank" rel="noreferrer">
          沪ICP备2021036254号-5
        </a>
        <span className="ml-2">增值电信业务经营许可证：沪B2-20220883</span>
      </div>
    </div>
  );
};

export default Login;

function getMenuKey(url: string): any {
  let key;
  switch (url) {
    case "main":
      key = "/";
      break;
    case "hy/hyEmployee":
      key = "hyEmployee";
      break;
    case "hy/hySite":
      key = "hySite";
      break;
    case "hy/hyOrgLine":
      key = "hyOrgLine";
      break;
    case "hy/hyOrder":
      key = "hyOrder";
      break;
    case "hy/hyPaiOrder":
      key = "hyPaiOrder";
      break;
    case "hy/hyStatistics/finance":
      key = "financeConfirm";
      break;
    case "hy/employeeJs":
      key = "employeeJs";
      break;
    case "hy/hyAddress":
      key = "hyAddress";
      break;
    case "hy/hyUserAccount":
      key = "hyUserAccount";
      break;
    case "hy/hyPayment":
      key = "hyPayment";
      break;
    case "hy/hyStatistics":
      key = "hyStatistics";
      break;
    case "hy/freightTx":
      key = "hyTx";
      break;
    /**************下面部分是自寄客户菜单了***********************************/
    case "hy/custOrder/sendOrder": // 寄件订单
      key = "custSendOrder";
      break;
    case "hy/custOrder/rcvOrder": // 收件订单
      key = "custRcvOrder";
      break;
    case "hy/custSite": // 站点查询
      key = "custSite";
      break;
    case "hy/custAddress": // 地址簿
      key = "custAddress";
      break;
    case "hy/hyChild": // 子账号
      key = "custChild";
      break;
    case "hy/custTx": // 我的账户
      key = "custTx";
      break;
    case "hy/custJs": // 货款结算
      key = "custJs";
      break;
    /**************下面部分是客服菜单了***********************************/
    case "hy/hyKefu": // 客服管理
      key = "hyKefu";
      break;
    case "hy/hyWuliu": // 物流客户
      key = "hyWuliu";
      break;
    case "hy/hyCust": // 自寄用户
      key = "hyCust";
      break;
    case "hy/smsSend": // 查看短信
      key = "smsSend";
      break;
    case "hy/loginLogs": // 用户登录
      key = "loginLogs";
      break;
    case "hy/hyWuliu/statisticsMonth": // 运单统计
      key = "sysstatistics";
      break;
    case "hy/hyWatch": // 运单监控
      key = "hyWatch";
      break;
    case "hy/hyCancel": // 订单取消
      key = "hyCancel";
      break;
    case "hy/shareAddress": // 共享地址
      key = "shareAddress";
      break;
    case "hy/hyOrderJS": // 货款结算
      key = "hyOrderJS";
      break;
    case "hy/userTx": // 提现管理
      key = "userTx";
      break;
    case "sys/user/": // 用户管理
      key = "sysuser";
      break;
    case "hy/bank": // 银行列表
      key = "bank";
      break;
    case "hy/wxminiPic": // 小程序首页图片
      key = "wxminiPic";
      break;
    case "hy/wxminiPic": // 小程序首页图片
      key = "wxminiPic";
      break;
    case "sys/role": // 角色管理
      key = "sysrole";
      break;
    case "sys/menu/": // 系统菜单
      key = "sysmenu";
      break;
    case "druid/index.html": // 运行监控
      key = "druid";
      break;
    case "common/generator": // 代码生成
      key = "codegenerator";
      break;
    case "common/job": // 计划任务
      key = "sysjob";
      break;
    case "common/log": // 系统日志
      key = "syslog";
      break;
    case "sys/online": // 在线用户
      key = "online";
      break;
    default:
      key = "?";
      break;
  }
  return key;
}
