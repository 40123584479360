import { PictureOutlined } from "@ant-design/icons";
import { Button, Space, Tag } from "antd";
import React from "react";

const Index: React.FC<any> = (props: any) => {
  return (
    <>
      <div>
        <span>{props.hyOrder.firstStoreName}</span>
        {props.hyOrder.lineName && <span>（{props.hyOrder.lineName}）</span>}
      </div>
      <Tag color="green">{getOrderByName(props.hyOrder.orderType)}</Tag>
      <Space size={2} className="mt-1">
        <Tag style={{ margin: 0 }} color="blue">
          包裹{props.hyOrder.productNumber}
        </Tag>
        {props.hyOrder.imageCount > 0 && props.clickPic && (
          <Button
            type="primary"
            icon={<PictureOutlined />}
            size="small"
            onClick={props.clickPic}
          >
            {props.hyOrder.imageCount}
          </Button>
        )}
      </Space>
    </>
  );
};

export default Index;

const getOrderByName = (orderType: string) => {
  switch (orderType) {
    case "1":
      return "取件员下单";
    case "2":
      return "客户下单";
    case "3":
      return "后台出单";
    case "4":
      return "汽配经下单";
    default:
      return "-";
  }
};
