import SingleSearch from "@/components/SingleSearch";
import request from "@/service";
import { DeleteOutlined, EditOutlined, FormOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm, Space, Spin, Table, Tag } from "antd";
import qs from "qs";
import _ from "lodash";
import React, { Suspense, useEffect, useState, lazy } from "react";

const JsOrder = lazy(() => import("./JsOrder"));

const HyAddress = () => {
  const [curId, setCurId] = useState("");
  const [showOrder, setShowOrder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  let _keyWords = "";
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const columns = [
    {
      title: "批次号",
      dataIndex: "pcNo",
      key: "pcNo",
    },
    {
      title: "物流公司",
      dataIndex: "deptName",
      key: "deptName",
    },
    {
      title: "金额",
      dataIndex: "money",
      key: "money",
    },
    {
      title: "订单数",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "结算银行",
      dataIndex: "payBankName",
      key: "payBankName",
    },
    {
      title: "开户人",
      dataIndex: "payAccountName",
      key: "payAccountName",
    },
    {
      title: "银行卡号",
      dataIndex: "payAccountNo",
      key: "payAccountNo",
    },
    {
      title: "操作人",
      dataIndex: "createBy",
      key: "createBy",
      render: (_: any, record: any) =>
        record.createBy === "sys" ? "sys" : "客户",
    },
    {
      title: "申请日期",
      dataIndex: "createDate",
      key: "createDate",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (_: any, record: any) =>
        record.status === "2" ? (
          <Tag color="blue">已结算</Tag>
        ) : record.categoryType === "3" ? (
          <Tag color="orange">申请中</Tag>
        ) : (
          "-"
        ),
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      render: (_: any, record: any) => (
        <Button
          type="primary"
          size="small"
          onClick={() => clickOrder(record.id)}
        >
          结算订单
        </Button>
      ),
    },
  ];
  const clickOrder = (id: any) => {
    setCurId(id);
    setShowOrder(true);
  };
  const doSearch = (keyWords: string) => {
    _keyWords = keyWords;
    loadData();
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/hyOrderJS/list",
        params: {
          ...pager,
          keyWords: _keyWords,
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  return (
    <div className="container">
      <div className="flex mb-2">
        <SingleSearch placeholder="姓名、电话等" doSearch={doSearch} />
      </div>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <JsOrder
          jsId={curId}
          open={showOrder}
          onCancel={() => {
            setShowOrder(false);
          }}
        />
      </Suspense>
    </div>
  );
};

export default HyAddress;
