import React, { Suspense, useEffect, useState, lazy } from "react";
import request from "@/service";
import { Button, Form, Select, Spin, Table, DatePicker, Tag } from "antd";
import moment from "moment";
import _ from "lodash";

const CustSettlementOrder = lazy(() => import("./CustSettlementOrder"));
const { Option } = Select;

const Index: React.FC<any> = () => {
  const [loading, setLoading] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [curId, setCurId] = useState("");
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const columns = [
    {
      title: "单号",
      dataIndex: "pcNo",
      key: "pcNo",
    },
    {
      title: "公司名称",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "货款结算",
      dataIndex: "money",
      key: "money",
      render: (_: any, record: any) => (
        <>
          <span className="red-price">{record.money}</span>
          <span className="ml-2 mr-2">({record.count}单)</span>
          {record.payType === "1" ? (
            <Tag color="green">线下结算</Tag>
          ) : (
            <Tag color="blue">线上结算</Tag>
          )}
        </>
      ),
    },
    {
      title: "日期",
      dataIndex: "createDate",
      key: "createDate",
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      render: (_: any, record: any) => (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            setCurId(record.id);
            setShowOrder(true);
          }}
        >
          查看订单
        </Button>
      ),
    },
  ];
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/custJs/list",
        params: {
          ...pager,
          type: "2",
          keyWords: "",
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  return (
    <div className="container">
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          current: pager.pageNo,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <CustSettlementOrder
          jsId={curId}
          open={showOrder}
          onCancel={() => {
            setShowOrder(false);
          }}
        />
      </Suspense>
    </div>
  );
};

export default Index;
