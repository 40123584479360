import request from "@/service";
import { Descriptions, Spin } from "antd";
import React, { useEffect, useState } from "react";

const Index: React.FC<any> = () => {
  const [loading, setLoading] = useState(false);
  const [hyOrg, setHyOrg] = useState<any>({});
  useEffect(() => {
    loadData();
  }, []);
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/custTx/getRegistInfo",
        params: {},
      });
      setHyOrg(res.result);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  return (
    <Spin spinning={loading}>
      <div className="ml-10 mr-10 mb-10">
        <Descriptions bordered size="default" column={1}>
          <Descriptions.Item label="公司名称">
            {hyOrg?.deptName}
          </Descriptions.Item>
          <Descriptions.Item label="地址">
            <>
              <span>{hyOrg?.provinceName}</span>
              <span>{hyOrg?.cityName}</span>
              <span>{hyOrg?.areaName}</span>
              <span>{hyOrg?.address}</span>
            </>
          </Descriptions.Item>
          <Descriptions.Item label="管理员姓名">
            {hyOrg?.userName}
          </Descriptions.Item>
          <Descriptions.Item label="管理员账户">
            {hyOrg?.userCode}
          </Descriptions.Item>
          <Descriptions.Item label="手机号">{hyOrg?.phone}</Descriptions.Item>
        </Descriptions>
      </div>
    </Spin>
  );
};
export default Index;
