import SingleSearch from "@/components/SingleSearch";
import request from "@/service";
import { DeleteOutlined, EditOutlined, FormOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm, Space, Spin, Table, Tag } from "antd";
import qs from "qs";
import _ from "lodash";
import React, { Suspense, useEffect, useState, lazy } from "react";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  let _keyWords = "";
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const columns = [
    {
      title: "手机号",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "短信内容",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "时间",
      dataIndex: "creatDate",
      key: "creatDate",
    },
  ];
  const doSearch = (keyWords: string) => {
    _keyWords = keyWords;
    loadData();
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/smsSend/list",
        params: {
          ...pager,
          keyWords: _keyWords,
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  return (
    <div className="container">
      <div className="flex mb-2">
        <SingleSearch placeholder="手机号" doSearch={doSearch} />
      </div>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
    </div>
  );
};

export default Index;
