import React from "react";
import "./less/NoPermission.less";
import NOPER_3 from "../assets/imgs/nopermission.png";
import { Button } from "antd";
import { useLocation } from "react-router-dom";

const Index: React.FC = () => {
  const location = useLocation();
  console.log("location-->", location);

  return (
    <div className="text-center h-screen bg-white">
      <div className="no-permission">
        <div className="content">
          <img className="f0f-bg-img" src={NOPER_3} />
          <div className="bottom">
            <div className="notice">
              <span className="text">ERROR</span>
              <span className="text ml-6 mr-6">对不起，您没有访问权限！</span>
              <span className="text">点击返回首页，或者刷新页面。</span>
            </div>
            <div className="btns">
              <Button
                shape="round"
                type="primary"
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                返回首页
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Index;
