import React from "react";
import "./less/Home.less";

const App: React.FC = () => {
  return (
    <div className="container">
      <div className="home"></div>
    </div>
  );
};
export default App;
