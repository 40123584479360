let BASE_URL = "";
const TIME_OUT = 60000;
const mock = false;

if (process.env.NODE_ENV === "development") {
  BASE_URL = "/api";
  if (mock) {
    BASE_URL = "/mockapi";
  }
} else if (process.env.NODE_ENV === "production") {
  // BASE_URL = "http://127.0.0.1:8181";
  BASE_URL = "/chhy";
}

export { BASE_URL, TIME_OUT };
