import SingleSearch from "@/components/SingleSearch";
import request from "@/service";
import {
  Button,
  Form,
  Input,
  message,
  Popconfirm,
  Space,
  Spin,
  Table,
  Tooltip,
} from "antd";
import qs from "qs";
import _ from "lodash";
import React, { Suspense, useEffect, useState, lazy } from "react";

const AccountInfo = lazy(() => import("@/components/AccountInfo"));
const RecordPc = lazy(() => import("./RecordPc"));
const OnlineOrder = lazy(() => import("./OnlineOrder"));
const OfflineOrder = lazy(() => import("./OfflineOrder"));
const CustOrder = lazy(() => import("./CustOrder"));
const OnlineOrderTx = lazy(() => import("./OnlineOrderTx"));

const HyAddress = () => {
  const [form] = Form.useForm();
  const [curId, setCurId] = useState("");
  const [showOnlineOrderTx, setShowOnlineOrderTx] = useState(false);
  const [showCustOrder, setShowCustOrder] = useState(false);
  const [submiJsDaysLoading, setSubmiJsDaysLoading] = useState(false);
  const [showOnlineOrder, setShowOnlineOrder] = useState(false);
  const [showOfflineOrder, setShowOfflineOrder] = useState(false);
  const [showEditJsDays, setShowEditJsDays] = useState(false);
  const [productJsDays, setProductJsDays] = useState(0);
  const [showRecord, setShowRecord] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jsLoading, setJsLoading] = useState(false);
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const [curUserAccountId, setCurUserAccountId] = useState("");
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  let _keyWords = "";
  useEffect(() => {
    loadJsDays();
  }, []);
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const refresh = () => {
    loadData();
  };
  const columns = [
    {
      title: "公司名称",
      dataIndex: "company",
      width: 160,
      key: "company",
      render: (_: any, record: any) =>
        record.accountType === "1" ? (
          <span>{record.company}(自寄)</span>
        ) : (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setCurUserAccountId(record.userAccountId);
              setShowAccountInfo(true);
            }}
          >
            {record.company}
          </a>
        ),
    },
    {
      title: "线上订单数量",
      dataIndex: "onlineNum",
      key: "onlineNum",
      render: (_: any, record: any) =>
        record.onlineNum <= 0 ? (
          "-"
        ) : (
          <>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                setCurUserAccountId(record.userAccountId);
                setShowOnlineOrder(true);
              }}
            >
              {record.onlineNum}单
            </Button>
            <span className="red-price ml-2">{record.onlineMoney}</span>
          </>
        ),
    },
    {
      title: "收款银行",
      dataIndex: "payBankName",
      key: "payBankName",
      render: (_: any, record: any) =>
        record.accountType === "1" ? "-" : <span>{record.payBankName}</span>,
    },
    {
      title: "收款人",
      dataIndex: "payAccountName",
      key: "payAccountName",
      render: (_: any, record: any) =>
        record.accountType === "1" ? "-" : <span>{record.payAccountName}</span>,
    },
    {
      title: "收款卡号",
      dataIndex: "payAccountNo",
      key: "payAccountNo",
      render: (_: any, record: any) =>
        record.accountType === "1" ? "-" : <span>{record.payAccountNo}</span>,
    },
    {
      title: "线下订单数量",
      dataIndex: "offlineNum",
      key: "offlineNum",
      render: (_: any, record: any) =>
        record.offlineNum <= 0 ? (
          "-"
        ) : (
          <>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                setCurUserAccountId(record.userAccountId);
                setShowOfflineOrder(true);
              }}
            >
              {record.offlineNum}单
            </Button>
            <span className="red-price ml-2">{record.offlineMoney}</span>
          </>
        ),
    },
    {
      title: "客户申请结算",
      dataIndex: "applyNum",
      key: "applyNum",
      render: (_: any, record: any) =>
        record.applyNum <= 0 ? (
          "-"
        ) : (
          <>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                setCurUserAccountId(record.userAccountId);
                setShowCustOrder(true);
              }}
            >
              {record.applyNum}单
            </Button>
            <span className="red-price ml-2">{record.applyMoney}</span>
          </>
        ),
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      render: (_: any, record: any) => (
        <Space size="small">
          {record.onlineNum > 0 && (
            <Button
              type="primary"
              size="small"
              style={{
                backgroundColor: "orange",
                borderColor: "orange",
              }}
              onClick={() => {
                setCurUserAccountId(record.userAccountId);
                setShowOnlineOrderTx(true);
              }}
            >
              线上订单结算
            </Button>
          )}
          {record.offlineNum > 0 && (
            <Popconfirm
              title="确定要结算线下订单吗？"
              placement="topRight"
              onConfirm={() => submitJsOffline(record.userAccountId)}
            >
              <Button type="primary" size="small">
                线下订单结算
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
  const doSearch = (keyWords: string) => {
    _keyWords = keyWords;
    loadData();
  };
  const onFinish = (values: any) => {
    console.log("Finish:", values);
    if (_.isEmpty(values["jsDays"] + "")) {
      return message.error("请输入结算天数！");
    }
    submitJsDays(values["jsDays"]);
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/hyPayment/list",
        params: {
          ...pager,
          keyWords: _keyWords,
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  const loadJsDays = async () => {
    try {
      const res: any = await request.get({
        url: "/hy/hyPayment/getJsDays",
        params: {},
      });
      setProductJsDays(res.result);
    } catch (err) {
      console.log("err-->", err);
    }
  };
  const submitJsOffline = async (userAccountId: string) => {
    try {
      setJsLoading(true);
      const res: any = await request.post({
        url: "/hy/hyPayment/settleOffline",
        data: qs.stringify({
          userAccountId,
        }),
      });
      message.success("提交成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
    setJsLoading(false);
  };
  const submitJsDays = async (jsDays: number) => {
    try {
      setSubmiJsDaysLoading(true);
      const res: any = await request.post({
        url: "/hy/hyPayment/updateProductJsDays",
        data: qs.stringify({
          productJsDays: jsDays,
        }),
      });
      message.success("提交成功！");
      setProductJsDays(jsDays);
      setShowEditJsDays(false);
    } catch (err) {
      console.log("err-->", err);
    }
    setSubmiJsDaysLoading(false);
  };
  return (
    <div className="container">
      <div className="flex mb-2 items-center">
        <div className="flex items-center">
          <span>货款延迟结算：</span>
          <Tooltip placement="bottom" title="点击修改">
            <Button
              hidden={showEditJsDays}
              type="primary"
              onClick={() => {
                setShowEditJsDays(true);
                form.setFieldValue("jsDays", productJsDays);
              }}
            >
              {productJsDays}天
            </Button>
          </Tooltip>
          <Form
            hidden={!showEditJsDays}
            form={form}
            layout="inline"
            onFinish={onFinish}
            initialValues={{ jsDays: productJsDays }}
          >
            <Form.Item name="jsDays">
              <Input
                min={0}
                max={365}
                suffix="天"
                type="number"
                style={{ width: 100 }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="link"
                htmlType="submit"
                loading={submiJsDaysLoading}
              >
                保存
              </Button>
              <a
                className="text-gray-700"
                onClick={() => {
                  setShowEditJsDays(false);
                }}
              >
                取消
              </a>
            </Form.Item>
          </Form>
        </div>
        <div style={{ flex: 1 }}></div>
        <SingleSearch placeholder="公司名称" doSearch={doSearch} />
        <Button
          type="primary"
          onClick={() => {
            setShowRecord(true);
          }}
        >
          结算记录
        </Button>
      </div>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="userAccountId"
        dataSource={tableData}
        pagination={{
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <AccountInfo
          userAccountId={curUserAccountId}
          open={showAccountInfo}
          onCancel={() => {
            setShowAccountInfo(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <RecordPc
          open={showRecord}
          onCancel={() => {
            setShowRecord(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <OnlineOrder
          userAccountId={curUserAccountId}
          open={showOnlineOrder}
          onCancel={() => {
            setShowOnlineOrder(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <OfflineOrder
          userAccountId={curUserAccountId}
          open={showOfflineOrder}
          refresh={refresh}
          onCancel={() => {
            setShowOfflineOrder(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <CustOrder
          userAccountId={curUserAccountId}
          open={showCustOrder}
          refresh={refresh}
          onCancel={() => {
            setShowCustOrder(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <OnlineOrderTx
          userAccountId={curUserAccountId}
          open={showOnlineOrderTx}
          refresh={refresh}
          onCancel={() => {
            setShowOnlineOrderTx(false);
          }}
        />
      </Suspense>
    </div>
  );
};

export default HyAddress;
