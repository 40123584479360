import { Tabs } from "antd";
import React from "react";
import TxPanel from "./TxPanel";
import YuePanel from "./YuePanel";
import JsPanel from "./JsPanel";
import AccountPanel from "./AccountPanel";
import RegistInfoPanel from "./RegistInfoPanel";
import Auth from "@/components/Auth";

const Index = () => {
  const items = [
    { label: "提现记录", key: "1", children: <TxPanel /> }, // 务必填写 key
    { label: "余额记录", key: "2", children: <YuePanel /> },
    { label: "结算信息", key: "3", children: <JsPanel /> },
    { label: "账户信息", key: "4", children: <AccountPanel /> },
    { label: "注册信息", key: "5", children: <RegistInfoPanel /> },
  ];
  return (
    <div className="container h-full">
      <div className="bg-white">
        <Tabs items={items} defaultActiveKey="1" type="card" />
      </div>
    </div>
  );
};
export default Index;
