import SingleSearch from "@/components/SingleSearch";
import request from "@/service";
import { Button, message, Popconfirm, Table, Tag } from "antd";
import qs from "qs";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { LogoutOutlined } from "@ant-design/icons";

const HyAddress = () => {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  let _keyWords = "";
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const columns = [
    {
      title: "用户名",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "主机",
      dataIndex: "host",
      key: "host",
    },
    {
      title: "登录时间",
      dataIndex: "startTimestamp",
      key: "startTimestamp",
    },
    {
      title: "最后访问时间",
      dataIndex: "lastAccessTime",
      key: "lastAccessTime",
    },
    {
      title: "过期时间",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "操作",
      dataIndex: "ID",
      key: "ID",
      render: (_: any, record: any) => (
        <Popconfirm
          placement="topRight"
          title="确定要强制选中用户下线吗？"
          onConfirm={() => {
            submitUserLogout(record.id);
          }}
          okText="确定"
          cancelText="取消"
        >
          <Button
            type="primary"
            size="small"
            danger
            icon={<LogoutOutlined />}
          />
        </Popconfirm>
      ),
    },
  ];
  const doSearch = (keyWords: string) => {
    _keyWords = keyWords;
    loadData();
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/sys/online/list",
        params: {
          ...pager,
          name: _keyWords,
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  const submitUserLogout = async (id: string) => {
    try {
      const res: any = await request.post({
        url: "/sys/online/forceLogout/" + id,
        data: qs.stringify({
          id,
        }),
      });
      message.success("提交成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
  };
  return (
    <div className="container">
      <div className="flex mb-2">
        <SingleSearch placeholder="用户名" doSearch={doSearch} />
      </div>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
    </div>
  );
};

export default HyAddress;
