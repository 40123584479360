import { Table, Button, Spin, Space, Modal } from "antd";
import React, { Fragment, lazy, Suspense, useEffect, useState } from "react";
import request from "@/service";
import SingleSearch from "@/components/SingleSearch";
import MyDelFlag from "@/components/MyDelFlag";
import { FormOutlined, KeyOutlined } from "@ant-design/icons";

const AddEmplpyee = lazy(() => import("./AddEmplpyee"));
const EditEmplpyee = lazy(() => import("./EditEmplpyee"));
const ChangePwd = lazy(() => import("./ChangePwd"));

const HyEmployee = (props: any) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showChangePwd, setShowChangePwd] = useState(false);
  const [curId, setCurId] = useState("");
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  let _keyWords = "";
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const refresh = () => {
    setShowAdd(false);
    setShowEdit(false);
    loadData();
  };
  const columns = [
    {
      title: "登录名",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "姓名",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "手机号",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "日期",
      dataIndex: "createDate",
      key: "createDate",
    },
    {
      title: "状态",
      dataIndex: "myDelFlag",
      key: "myDelFlag",
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      render: (_: any, record: any) => (
        <Space size="small">
          <Button
            type="primary"
            size="small"
            icon={<FormOutlined />}
            onClick={() => clickEdit(record.id)}
          />
          <Button
            type="primary"
            size="small"
            icon={<KeyOutlined />}
            onClick={() => clickChangePwd(record.id)}
          />
        </Space>
      ),
    },
  ];
  const clickEdit = (id: any) => {
    setCurId(id);
    setShowEdit(true);
  };
  const clickChangePwd = (id: any) => {
    setCurId(id);
    setShowChangePwd(true);
  };
  const doSearch = (keyWords: string) => {
    _keyWords = keyWords;
    loadData();
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/hyEmployee/list",
        params: {
          ...pager,
          keyWords: _keyWords,
        },
      });
      if (res?.rows) {
        res?.rows.map((item: any, index: number) => {
          item.myDelFlag = (
            <Fragment key={item.id}>
              <MyDelFlag delFlag={item.delFlag} />
            </Fragment>
          );
        });
      }
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  return (
    <div className="container">
      <div className="flex mb-1.5">
        <SingleSearch placeholder="姓名或手机号" doSearch={doSearch} />
        <Button type="primary" onClick={() => setShowAdd(true)}>
          新建
        </Button>
      </div>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <AddEmplpyee
          open={showAdd}
          refresh={refresh}
          onCancel={() => {
            setShowAdd(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <EditEmplpyee
          userId={curId}
          open={showEdit}
          refresh={refresh}
          onCancel={() => {
            setShowEdit(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <ChangePwd
          userId={curId}
          open={showChangePwd}
          onCancel={() => {
            setShowChangePwd(false);
          }}
        />
      </Suspense>
    </div>
  );
};
export default HyEmployee;
