import SingleSearch from "@/components/SingleSearch";
import request from "@/service";
import {
  FormOutlined,
  KeyOutlined,
  OrderedListOutlined,
  TeamOutlined,
  IdcardOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Button, Space, Spin, Table, Tag } from "antd";
import _ from "lodash";
import React, { Suspense, useEffect, useState, lazy } from "react";

const EditCust = lazy(() => import("./EditCust"));
const CustChangePwd = lazy(() => import("./CustChangePwd"));
const CustOrder = lazy(() => import("./CustOrder"));
const CustUsers = lazy(() => import("./CustUsers"));
const CustAccount = lazy(() => import("./CustAccount"));
const CustAddress = lazy(() => import("./CustAddress"));
const CustAddressAll = lazy(() => import("./CustAddressAll"));

const Index = () => {
  const [curId, setCurId] = useState("");
  const [curUserId, setCurUserId] = useState("");
  const [curDeptId, setCurDeptId] = useState("");
  const [showChangePwd, setShowChangePwd] = useState(false);
  const [showCustOrder, setShowCustOrder] = useState(false);
  const [showCustAccount, setShowCustAccount] = useState(false);
  const [showCustAddress, setShowCustAddress] = useState(false);
  const [showCustAddressAll, setShowCustAddressAll] = useState(false);
  const [showCustUsers, setShowCustUsers] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  let _keyWords = "";
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const refresh = () => {
    setShowEdit(false);
    loadData();
  };
  const columns = [
    {
      title: "公司名称",
      width: 200,
      dataIndex: "deptName",
      key: "deptName",
      render: (_: any, record: any) => (
        <>
          <span>{record.deptName}</span>
          <br />
          <span>{record.createDate}</span>
        </>
      ),
    },
    {
      title: "管理员姓名",
      dataIndex: "userName",
      width: 150,
      key: "userName",
      render: (_: any, record: any) => (
        <>
          <span>{record.userName}</span>
          <br />
          <span>{record.userCode}</span>
        </>
      ),
    },
    {
      title: "手机号",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "地址",
      dataIndex: "address",
      key: "address",
      ellipsis: true,
      render: (_: any, record: any) => (
        <>
          <span>
            {record.provinceName}
            {record.cityName}
            {record.areaName}
          </span>
          <br />
          <span>{record.address}</span>
        </>
      ),
    },
    {
      title: "下单数量",
      dataIndex: "orderNum",
      key: "orderNum",
    },
    {
      title: "状态",
      dataIndex: "delFlag",
      key: "delFlag",
      width: 100,
      render: (_prams: any, record: any) => (
        <>
          {record.delFlag === "0" && <Tag color="green">正常</Tag>}
          {record.delFlag === "1" && <Tag color="red">禁用</Tag>}
        </>
      ),
    },
    {
      title: "操作",
      dataIndex: "id",
      width: 200,
      key: "id",
      render: (_: any, record: any) => (
        <Space size={5}>
          <Button
            type="primary"
            size="small"
            title="订单列表"
            icon={<OrderedListOutlined />}
            onClick={() => {
              setCurUserId(record.userId);
              setShowCustOrder(true);
            }}
          />
          <Button
            type="primary"
            size="small"
            title="员工列表"
            icon={<TeamOutlined />}
            onClick={() => {
              setCurId(record.id);
              setShowCustUsers(true);
            }}
          />
          <Button
            type="primary"
            size="small"
            title="收款账户"
            icon={<IdcardOutlined />}
            onClick={() => {
              setCurId(record.id);
              setShowCustAccount(true);
            }}
          />
          <Button
            type="primary"
            size="small"
            title="地址列表"
            icon={<SolutionOutlined />}
            onClick={() => {
              setCurDeptId(record.deptId);
              setShowCustAddress(true);
            }}
          />
          <Button
            type="primary"
            size="small"
            title="编辑"
            icon={<FormOutlined />}
            onClick={() => clickEdit(record)}
          />
          <Button
            type="primary"
            size="small"
            icon={<KeyOutlined />}
            onClick={() => clickChangePwd(record)}
          />
        </Space>
      ),
    },
  ];
  const clickChangePwd = (item: any) => {
    setCurUserId(item.userId);
    setShowChangePwd(true);
  };
  const clickEdit = (item: any) => {
    setCurId(item.id);
    setCurUserId(item.userId);
    setShowEdit(true);
  };
  const doSearch = (keyWords: string) => {
    _keyWords = keyWords;
    loadData();
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/hyCust/list",
        params: {
          ...pager,
          keyWords: _keyWords,
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  return (
    <div className="container">
      <div className="flex mb-2">
        <SingleSearch placeholder="姓名、电话等" doSearch={doSearch} />
        <Button
          type="primary"
          onClick={() => {
            setShowCustAddressAll(true);
          }}
        >
          地址簿
        </Button>
      </div>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <EditCust
          orgId={curId}
          userId={curUserId}
          open={showEdit}
          refresh={refresh}
          onCancel={() => {
            setShowEdit(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <CustChangePwd
          userId={curUserId}
          open={showChangePwd}
          onCancel={() => {
            setShowChangePwd(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <CustOrder
          userId={curUserId}
          open={showCustOrder}
          onCancel={() => {
            setShowCustOrder(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <CustUsers
          custId={curId}
          open={showCustUsers}
          onCancel={() => {
            setShowCustUsers(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <CustAccount
          custId={curId}
          refresh={() => {
            loadData();
            setShowCustAccount(false);
          }}
          open={showCustAccount}
          onCancel={() => {
            setShowCustAccount(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <CustAddress
          deptId={curDeptId}
          open={showCustAddress}
          onCancel={() => {
            setShowCustAddress(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <CustAddressAll
          open={showCustAddressAll}
          onCancel={() => {
            setShowCustAddressAll(false);
          }}
        />
      </Suspense>
    </div>
  );
};

export default Index;
