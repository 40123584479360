import { Table, Button, Spin, Space } from "antd";
import React, { Fragment, lazy, Suspense, useEffect, useState } from "react";
import request from "@/service";
import SingleSearch from "@/components/SingleSearch";
import { CalendarOutlined } from "@ant-design/icons";

const CustSiteTime = lazy(() => import("./CustSiteTime"));

const HySite = (props: any) => {
  const [showSiteTime, setShowSiteTime] = useState(false);
  const [curId, setCurId] = useState("");
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  let _keyWords = "";
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const columns = [
    {
      title: "站点名称",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: 160,
    },
    {
      title: "物流公司",
      dataIndex: "deptName",
      key: "deptName",
    },
    {
      title: "联系电话",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "省市区",
      dataIndex: "area",
      key: "area",
      ellipsis: true,
      render: (_: any, record: any) => (
        <Space size="middle">
          <span>
            {record.provinceName}
            {record.cityName}
            {record.areaName}
          </span>
        </Space>
      ),
    },
    {
      title: "具体地址",
      dataIndex: "address",
      key: "address",
      ellipsis: true,
    },
    {
      title: "服务范围",
      dataIndex: "serviceTxt",
      key: "serviceTxt",
      ellipsis: true,
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
      ellipsis: true,
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      render: (_: any, record: any) => (
        <Button
          type="primary"
          title="发车时间"
          size="small"
          icon={<CalendarOutlined />}
          onClick={() => {
            setCurId(record.id);
            setShowSiteTime(true);
          }}
        />
      ),
    },
  ];
  const doSearch = (keyWords: string) => {
    _keyWords = keyWords;
    loadData();
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/custSite/list",
        params: {
          ...pager,
          keyWords: _keyWords,
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  return (
    <div className="container">
      <div className="flex mb-1.5">
        <SingleSearch placeholder="站点名称" doSearch={doSearch} />
      </div>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <CustSiteTime
          siteId={curId}
          open={showSiteTime}
          onCancel={() => {
            setShowSiteTime(false);
          }}
        />
      </Suspense>
    </div>
  );
};
export default HySite;
