import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import {
  HomeOutlined,
  QuestionCircleOutlined,
  TeamOutlined,
  AimOutlined,
  BorderlessTableOutlined,
  UnorderedListOutlined,
  EditOutlined,
  CalculatorOutlined,
  AccountBookOutlined,
  SolutionOutlined,
  IdcardOutlined,
  DollarOutlined,
  LineChartOutlined,
  RedEnvelopeOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
  DownloadOutlined,
  ControlOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Dropdown,
  Menu,
  Divider,
  Modal,
  message,
  ConfigProvider,
  Button,
} from "antd";
import "./less/App.less";
import storageUtil from "@/utils/storage";
import _ from "lodash";
import ChangePwd from "@/components/ChangePwd";
import emitter from "@/utils/eventbus";
import head_icon from "../assets/imgs/head_icon.png";
import { Scrollbars } from "react-custom-scrollbars";

const { Header, Content, Sider } = Layout;

const App: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState([]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selectedKey, setSelectedKey] = useState("");
  const [userName, setUserName] = useState("");
  const [roleName, setRoleName] = useState("");
  const [rootDeptName, setRootDeptName] = useState("");
  const clickLogout = () => {
    Modal.confirm({
      title: "提示",
      icon: <ExclamationCircleOutlined />,
      content: "确定要退出登录吗？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        message.success("退出成功！");
        storageUtil.clear();
        setTimeout(() => {
          navigate("/login");
        }, 500);
      },
      onCancel() {},
    });
  };
  const menu = (
    <Menu
      items={[
        {
          label: (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                emitter.emit("showPwdDialog", true);
              }}
            >
              修改密码
            </a>
          ),
          key: "0",
        },
        {
          type: "divider",
        },
        {
          label: (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                clickLogout();
              }}
            >
              退出登录
            </a>
          ),
          key: "1",
        },
      ]}
    />
  );
  useEffect(() => {
    const userId = storageUtil.getItem("userId");
    if (_.isEmpty(userId)) {
      navigate("/login");
    } else {
      setRootDeptName(storageUtil.getItem("rootDeptName"));
      setUserName(storageUtil.getItem("userName"));
      setRoleName(storageUtil.getItem("roleName"));
      const userType = storageUtil.getItem("userType");
      const menus = storageUtil.getItem("menus");
      const arr: any = [];
      if (userType !== "3" && userType !== "5") {
        menus.forEach((item: any, index: number) => {
          arr.push({
            key: getMenuKey(item),
            icon: getMenuIcon(item),
            label: item.text,
          });
        });
      } else {
        // 客服
        menus.forEach((item: any, index: number) => {
          let menuItem: any = {};
          if (item.hasChildren) {
            menuItem = {
              key: getMenuKey(item),
              label: item.text,
              icon: getMenuIcon(item),
              children: [],
            };
            item.children.forEach((child: any, childIndex: number) => {
              menuItem.children.push({
                key: getMenuKey(child),
                label: child.text,
              });
            });
          } else {
            menuItem = {
              key: getMenuKey(item),
              icon: getMenuIcon(item),
              label: item.text,
            };
          }
          arr.push(menuItem);
        });
      }
      setMenuItems(arr);
      if (location.pathname === "/") {
        setSelectedKey("/");
      } else {
        setSelectedKey(location.pathname.split("/")[1]);
      }
    }
  }, []);
  const clickMenu = (key: string, keyPath: string[], domEvent: any) => {
    console.log("clickMenu-->", key, "===", keyPath);
    setSelectedKey(key);
    navigate(key);
  };
  const onOpenChange = (oKeys: string[]) => {
    console.log("openKeys-->", oKeys);
    if (oKeys && oKeys.length > 0) {
      setOpenKeys([oKeys[oKeys.length - 1]]);
    } else {
      setOpenKeys([]);
    }
  };
  return (
    <Layout className="flex flex-col h-screen">
      <Header className="header">
        <div className="content">
          <div className="flex-1">
            <span className="text-lg font-medium">{rootDeptName}</span>
          </div>
          <UserOutlined />
          <span className="ml-1 text-sm">
            {userName}【{roleName}】
          </span>
          <Button
            type="text"
            onClick={() => {
              emitter.emit("showPwdDialog", true);
            }}
          >
            <span className="text-white text-sm">修改密码</span>
          </Button>
          <Divider
            type="vertical"
            style={{
              borderLeft: "1px solid white",
              margin: 0,
            }}
          />
          <Button type="text" onClick={clickLogout}>
            <span className="text-white text-sm">退出登录</span>
          </Button>
        </div>
      </Header>
      <Layout className="flex-1" style={{ marginTop: "48px" }}>
        <Sider
          width={200}
          theme="light"
          style={{
            position: "fixed",
            left: 0,
            top: "48px",
            bottom: 0,
          }}
        >
          <Scrollbars>
            <div className="logo text-center text-white">
              <img src={head_icon} alt="" />
              <Dropdown overlay={menu} trigger={["click"]}>
                <a onClick={(e) => e.preventDefault()}>
                  <div className="mt-2">
                    <span>{userName}</span>
                    <br />
                    <span>【{roleName}】</span>
                  </div>
                </a>
              </Dropdown>
            </div>
            <Divider
              dashed
              style={{
                borderColor: "#bbb",
                margin: "10px 0",
              }}
            />
            <Menu
              theme="light"
              mode="inline"
              selectedKeys={[selectedKey]}
              openKeys={openKeys}
              items={menuItems}
              onOpenChange={(openKeys) => {
                onOpenChange(openKeys);
              }}
              onClick={({ key, keyPath, domEvent }) =>
                clickMenu(key, keyPath, domEvent)
              }
            />
          </Scrollbars>
        </Sider>
        <Content style={{ height: "100%", marginLeft: "200px" }}>
          <Outlet />
        </Content>
      </Layout>
      <ChangePwd />
    </Layout>
  );
};
function getMenuIcon(item: any): any {
  let icon;
  if (item.hasChildren) {
    switch (item.id) {
      case "776ca87bc646480aa544e24d7ad46e71": //用户管理
        icon = TeamOutlined;
        break;
      case "92a6a5d413cb4b9eb3085c969f1e9f04": //订单管理
        icon = UnorderedListOutlined;
        break;
      case "50ad15f7b1a4404bb3cb3cbbd20f19e3": // 财务管理
        icon = CalculatorOutlined;
        break;
      case "1": // 系统管理
        icon = ControlOutlined;
        break;
      case "6": // 系统工具
        icon = SettingOutlined;
        break;
      default:
        icon = QuestionCircleOutlined;
        break;
    }
    return React.createElement(icon);
  }
  const url: string = item.attributes.url;
  switch (url) {
    case "main":
      icon = HomeOutlined;
      break;
    case "hy/hyEmployee":
      icon = TeamOutlined;
      break;
    case "hy/hySite":
      icon = AimOutlined;
      break;
    case "hy/hyOrgLine":
      icon = BorderlessTableOutlined;
      break;
    case "hy/hyOrder":
      icon = UnorderedListOutlined;
      break;
    case "hy/hyPaiOrder":
      icon = EditOutlined;
      break;
    case "hy/hyStatistics/finance":
      icon = CalculatorOutlined;
      break;
    case "hy/employeeJs":
      icon = AccountBookOutlined;
      break;
    case "hy/hyAddress":
      icon = SolutionOutlined;
      break;
    case "hy/hyUserAccount":
      icon = IdcardOutlined;
      break;
    case "hy/hyPayment":
      icon = DollarOutlined;
      break;
    case "hy/hyStatistics":
      icon = LineChartOutlined;
      break;
    case "hy/freightTx":
      icon = RedEnvelopeOutlined;
      break;
    /**************下面部分是自寄客户菜单了***********************************/
    case "hy/custOrder/sendOrder": // 寄件订单
      icon = UploadOutlined;
      break;
    case "hy/custOrder/rcvOrder": // 收件订单
      icon = DownloadOutlined;
      break;
    case "hy/custSite": // 站点查询
      icon = AimOutlined;
      break;
    case "hy/custAddress": // 地址簿
      icon = SolutionOutlined;
      break;
    case "hy/hyChild": // 子账号
      icon = TeamOutlined;
      break;
    case "hy/custTx": // 我的账户
      icon = IdcardOutlined;
      break;
    case "hy/custJs": // 货款结算
      icon = RedEnvelopeOutlined;
      break;
    default:
      icon = QuestionCircleOutlined;
      break;
  }
  return React.createElement(icon);
}
function getMenuKey(item: any): any {
  if (item.hasChildren) {
    return item.id;
  }
  const url: string = item.attributes.url;
  let key;
  switch (url) {
    case "main":
      key = "/";
      break;
    case "hy/hyEmployee":
      key = "hyEmployee";
      break;
    case "hy/hySite":
      key = "hySite";
      break;
    case "hy/hyOrgLine":
      key = "hyOrgLine";
      break;
    case "hy/hyOrder":
      key = "hyOrder";
      break;
    case "hy/hyPaiOrder":
      key = "hyPaiOrder";
      break;
    case "hy/hyStatistics/finance":
      key = "financeConfirm";
      break;
    case "hy/employeeJs":
      key = "employeeJs";
      break;
    case "hy/hyAddress":
      key = "hyAddress";
      break;
    case "hy/hyUserAccount":
      key = "hyUserAccount";
      break;
    case "hy/hyPayment":
      key = "hyPayment";
      break;
    case "hy/hyStatistics":
      key = "hyStatistics";
      break;
    case "hy/freightTx":
      key = "hyTx";
      break;
    /**************下面部分是自寄客户菜单了***********************************/
    case "hy/custOrder/sendOrder": // 寄件订单
      key = "custSendOrder";
      break;
    case "hy/custOrder/rcvOrder": // 收件订单
      key = "custRcvOrder";
      break;
    case "hy/custSite": // 站点查询
      key = "custSite";
      break;
    case "hy/custAddress": // 地址簿
      key = "custAddress";
      break;
    case "hy/hyChild": // 子账号
      key = "custChild";
      break;
    case "hy/custTx": // 我的账户
      key = "custTx";
      break;
    case "hy/custJs": // 货款结算
      key = "custJs";
      break;
    /**************下面部分是客服菜单了***********************************/
    case "hy/hyKefu": // 客服管理
      key = "hyKefu";
      break;
    case "hy/hyWuliu": // 物流客户
      key = "hyWuliu";
      break;
    case "hy/hyCust": // 自寄用户
      key = "hyCust";
      break;
    case "hy/smsSend": // 查看短信
      key = "smsSend";
      break;
    case "hy/loginLogs": // 用户登录
      key = "loginLogs";
      break;
    case "hy/hyWuliu/statisticsMonth": // 运单统计
      key = "sysstatistics";
      break;
    case "hy/hyWatch": // 运单监控
      key = "hyWatch";
      break;
    case "hy/hyCancel": // 订单取消
      key = "hyCancel";
      break;
    case "hy/shareAddress": // 共享地址
      key = "shareAddress";
      break;
    case "hy/hyOrderJS": // 货款结算
      key = "hyOrderJS";
      break;
    case "hy/userTx": // 提现管理
      key = "userTx";
      break;
    case "sys/user/": // 用户管理
      key = "sysuser";
      break;
    case "hy/bank": // 银行列表
      key = "bank";
      break;
    case "hy/wxminiPic": // 微信小程序首页图片
      key = "wxminiPic";
      break;
    case "sys/role": // 角色管理
      key = "sysrole";
      break;
    case "sys/menu/": // 系统菜单
      key = "sysmenu";
      break;
    case "druid/index.html": // 运行监控
      key = "druid";
      break;
    case "common/generator": // 代码生成
      key = "codegenerator";
      break;
    case "common/job": // 计划任务
      key = "sysjob";
      break;
    case "common/log": // 系统日志
      key = "syslog";
      break;
    case "sys/online": // 在线用户
      key = "online";
      break;
    default:
      key = "?";
      break;
  }
  if (key === "?") {
    console.log("key=?==>", item.text);
  }
  return key;
}
export default App;
