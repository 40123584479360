import { InfoCircleFilled } from "@ant-design/icons";
import { Button, Popconfirm, Space, Tag, Tooltip } from "antd";
import React, { ReactNode } from "react";
import _ from "lodash";

const Index: React.FC<any> = (props: any) => {
  return (
    <>
      <div>
        {props.clickOrderNo ? (
          <a
            href="#"
            onClick={(e: any) => {
              e.preventDefault();
              props.clickOrderNo();
            }}
          >
            {props.hyOrder.orderNo}
          </a>
        ) : (
          <span>{props.hyOrder.orderNo}</span>
        )}
      </div>
      <div>{props.hyOrder.createDate}</div>
      <Space size={6}>
        {statusTag(props.hyOrder)}
        {delButton(props)}
      </Space>
    </>
  );
};

export default Index;

const delButton = (props: any): ReactNode => {
  if (props.hyOrder.status === "1") {
    return (
      props.deleteOrder && (
        <Popconfirm
          placement="topRight"
          title="确定取消订单吗？"
          onConfirm={props.deleteOrder}
          okText="确定"
          cancelText="取消"
        >
          <Button type="primary" size="small">
            取消订单
          </Button>
        </Popconfirm>
      )
    );
  }
  return "";
};
const statusTag = (hyOrder: any): ReactNode => {
  let str = "";
  switch (hyOrder.status) {
    case "0":
      return <Tag style={{ margin: 0 }}>已取消</Tag>;
    case "1":
      str = "客户下单";
      break;
    case "2":
      str = "待取件";
      break;
    case "3":
      str = "已取件";
      break;
    case "4":
      str = "已到站点";
      break;
    case "5":
      str = "运输中";
      break;
    case "6":
      str = "待派送";
      break;
    case "7":
      str = "派送中";
      break;
    case "8":
      str = "已签收";
      break;
    case "9":
      return (
        <Tooltip
          placement="topLeft"
          title={`作废原因：${hyOrder.invalidRemark}`}
        >
          <Tag style={{ margin: 0 }} color="red">
            作废
            <InfoCircleFilled className="ml-0.5" />
          </Tag>
        </Tooltip>
      );
      break;
    case "10":
      str = "跑腿中";
      break;
    default:
      str = "";
      break;
  }
  return _.isEmpty(str) ? (
    ""
  ) : (
    <Tag style={{ margin: 0 }} color="green">
      {str}
    </Tag>
  );
};
