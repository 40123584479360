import React from "react";
import { Tag } from "antd";

const MyDelFlag: React.FC<any> = (props) => {
  if (props.delFlag === "0") {
    return <Tag color="green">正常</Tag>;
  }
  return <Tag color="red">禁用</Tag>;
};

export default MyDelFlag;
