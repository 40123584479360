import React, { ReactNode } from "react";
import _ from "lodash";
import { Button, Popconfirm, Tag, TagType } from "antd";

const Index: React.FC<any> = (props: any) => {
  return (
    <div>
      <div>
        <span className="text-price text-red-500">{props.hyOrder.freight}</span>
        <span className="ml-1">
          {props.hyOrder.freightType === "1" ? "寄方付" : "收方付"}
        </span>
      </div>
      {props.hyOrder.ptType !== "1" && (
        <div>{getStatusName(props.hyOrder)}</div>
      )}
    </div>
  );
};

export default Index;

const getStatusName = (hyOrder: any): ReactNode => {
  if (hyOrder.freightStatus === "1") {
    return <Tag color="red">待支付</Tag>;
  }
  if (hyOrder.freightStatus === "2" || hyOrder.freightStatus === "4") {
    let str = "线下支付";
    if (hyOrder.freightPayType === "1") {
      str = "运费劵";
    } else if (hyOrder.freightPayType === "2") {
      str = "月结";
    }
    <Tag color="green">{str}</Tag>;
  } else if (hyOrder.freightStatus === "3" || hyOrder.freightStatus === "5") {
    return <Tag color="green">线上支付</Tag>;
  }
  return "";
};
