import SingleSearch from "@/components/SingleSearch";
import request from "@/service";
import { DeleteOutlined, EditOutlined, FormOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm, Space, Spin, Table, Tag } from "antd";
import qs from "qs";
import _ from "lodash";
import React, { Suspense, useEffect, useState, lazy } from "react";

const EditAddress = lazy(() => import("./EditAddress"));
const ShareAllMap = lazy(() => import("@/components/ShareAllMap"));
const SingleMap = lazy(() => import("@/components/SingleMap"));

const HyAddress = () => {
  const [curId, setCurId] = useState("");
  const [curItem, setCurItem] = useState({});
  const [showAllMap, setShowAllMap] = useState(false);
  const [showSingleMap, setShowSingleMap] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  let _keyWords = "";
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const refresh = () => {
    setShowEdit(false);
    loadData();
  };
  const columns = [
    {
      title: "机构名称",
      dataIndex: "deptName",
      key: "deptName",
    },
    {
      title: "公司",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "姓名",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "联系电话",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "地址",
      dataIndex: "address",
      key: "address",
      ellipsis: true,
      render: (_: any, record: any) => (
        <span>
          {record.provinceName}
          {record.cityName}
          {record.areaName}
          {record.address}
        </span>
      ),
    },
    {
      title: "片区",
      dataIndex: "line",
      key: "line",
    },
    {
      title: "基准运费",
      dataIndex: "freight",
      key: "freight",
      render: (_: any, record: any) => (
        <span className="text-price">{record.freight}</span>
      ),
    },
    {
      title: "类别",
      dataIndex: "categoryType",
      key: "categoryType",
      render: (_: any, record: any) =>
        record.categoryType === "1" ? (
          <Tag color="green">修理厂</Tag>
        ) : record.categoryType === "2" ? (
          <Tag color="blue">汽配商</Tag>
        ) : record.categoryType === "3" ? (
          <Tag color="red">物流公司</Tag>
        ) : (
          "-"
        ),
    },
    {
      title: "坐标",
      dataIndex: "location",
      key: "location",
      render: (_: any, record: any) =>
        record.hasLonLat ? (
          <Button
            type="link"
            onClick={() => {
              setCurItem(record);
              setShowSingleMap(true);
            }}
          >
            查看
          </Button>
        ) : (
          <Button
            type="link"
            danger
            onClick={() => {
              setCurItem(record);
              setShowSingleMap(true);
            }}
          >
            去标记
          </Button>
        ),
    },
    {
      title: "主营项目",
      dataIndex: "zyxm",
      key: "zyxm",
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
      ellipsis: true,
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      render: (_: any, record: any) => (
        <Space size="small">
          <Button
            type="primary"
            size="small"
            title="编辑"
            icon={<FormOutlined />}
            onClick={() => clickEdit(record.id)}
          />
          <Popconfirm
            title="确定要删除地址吗？"
            placement="topRight"
            onConfirm={() => deleteAddress(record.id)}
          >
            <Button
              type="primary"
              danger
              size="small"
              title="删除"
              loading={delLoading}
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const clickEdit = (id: any) => {
    setCurId(id);
    setShowEdit(true);
  };
  const doSearch = (keyWords: string) => {
    _keyWords = keyWords;
    loadData();
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/shareAddress/list",
        params: {
          ...pager,
          keyWords: _keyWords,
          gxFlag: "1",
        },
      });
      if (res.rows && res.rows.length > 0) {
        res.rows.forEach((item: any) => {
          item.hasLonLat =
            !_.isEmpty(item.latitude) && !_.isEmpty(item.longitude);
        });
      }
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  const deleteAddress = async (id: string) => {
    try {
      setDelLoading(true);
      const res: any = await request.post({
        url: "/hy/hyAddress/gxFlag",
        data: qs.stringify({
          id,
        }),
      });
      message.success("删除成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
    setDelLoading(false);
  };
  return (
    <div className="container">
      <div className="flex mb-2">
        <SingleSearch placeholder="姓名、电话等" doSearch={doSearch} />
        <Button type="primary" onClick={() => setShowAllMap(true)}>
          全部地图
        </Button>
      </div>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <EditAddress
          addressId={curId}
          open={showEdit}
          refresh={refresh}
          onCancel={() => {
            setShowEdit(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <ShareAllMap
          open={showAllMap}
          onCancel={() => {
            setShowAllMap(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <SingleMap
          hyAddress={curItem}
          open={showSingleMap}
          refresh={loadData}
          onCancel={() => {
            setShowSingleMap(false);
          }}
        />
      </Suspense>
    </div>
  );
};

export default HyAddress;
