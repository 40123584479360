import SingleSearch from "@/components/SingleSearch";
import request from "@/service";
import { Button, message, Popconfirm, Table, Tag } from "antd";
import qs from "qs";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";

const HyAddress = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  let _keyWords = "";
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const columns = [
    // {
    //   title: "用户Id",
    //   dataIndex: "userId",
    //   key: "userId",
    // },
    {
      title: "用户名",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "操作",
      dataIndex: "operation",
      key: "operation",
    },
    // {
    //   title: "用时",
    //   dataIndex: "time",
    //   key: "time",
    // },
    {
      title: "方法",
      dataIndex: "method",
      key: "method",
    },
    {
      title: "参数",
      dataIndex: "params",
      key: "params",
    },
    // {
    //   title: "IP地址",
    //   dataIndex: "ip",
    //   key: "ip",
    // },
    {
      title: "创建时间",
      dataIndex: "gmtCreate",
      key: "gmtCreate",
    },
    {
      title: "操作",
      dataIndex: "ID",
      key: "ID",
      render: (_: any, record: any) => (
        <Popconfirm
          placement="topRight"
          title="确定要删除日志吗？"
          onConfirm={() => {
            deleteLog(record.id);
          }}
          okText="确定"
          cancelText="取消"
        >
          <Button
            type="primary"
            size="small"
            danger
            icon={<DeleteOutlined />}
          />
        </Popconfirm>
      ),
    },
  ];
  const doSearch = (keyWords: string) => {
    _keyWords = keyWords;
    loadData();
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/common/log/list2",
        params: {
          ...pager,
          sort: "gmt_create",
          order: "desc",
          username: _keyWords,
        },
      });
      setTotal(res?.result?.total);
      setTableData(res?.result?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  const deleteLog = async (id: string) => {
    try {
      const res: any = await request.post({
        url: "/common/log/remove",
        data: qs.stringify({
          id,
        }),
      });
      message.success("删除成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
  };
  const deleteLogs = async () => {
    console.log("selectedRowKeys-->", selectedRowKeys);
    try {
      const res: any = await request.post({
        url: "/common/log/batchRemove2",
        data: qs.stringify({
          idStr: selectedRowKeys.join(","),
        }),
      });
      message.success("删除成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
  };
  return (
    <div className="container">
      <div className="flex mb-2">
        <SingleSearch placeholder="用户名" doSearch={doSearch} />
        <Popconfirm
          placement="topRight"
          title="确定要删除选中的日志吗？"
          onConfirm={() => {
            if (!selectedRowKeys || selectedRowKeys.length <= 0) {
              return message.error("请选择要删除的日志！");
            }
            deleteLogs();
          }}
          okText="确定"
          cancelText="取消"
        >
          <Button
            type="primary"
            disabled={selectedRowKeys.length <= 0}
            danger
            icon={<DeleteOutlined />}
          >
            删除
          </Button>
        </Popconfirm>
      </div>
      <Table
        rowSelection={rowSelection}
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
    </div>
  );
};

export default HyAddress;
