import { message, Form, Modal, Input } from "antd";
import React, { useEffect, useState } from "react";
import emitter from "@/utils/eventbus";
import request from "@/service";
import qs from "qs";

const ChangePwd: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  emitter.on("showPwdDialog", (val) => {
    setOpen(val);
  });
  useEffect(() => {
    return emitter.off("showPwdDialog");
  }, []);
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        resetUserPwd(values);
      })
      .catch((info) => {
        console.log("validate Failed：", info);
      });
  };
  const closeModal = () => {
    form.resetFields();
    setOpen(false);
  };
  const resetUserPwd = async (values: any) => {
    try {
      setIsLoading(true);
      const res = await request.post({
        url: "/sys/user/resetUserPwd",
        data: qs.stringify({ ...values }),
        showLoading: true,
      });
      message.success("修改成功！");
      closeModal();
    } catch (err: any) {
      console.log("err-->", err);
    }
    setIsLoading(false);
  };
  return (
    <Modal
      title="修改密码"
      centered
      cancelText="取消"
      okText="确定"
      onOk={handleOk}
      onCancel={closeModal}
      open={open}
      confirmLoading={isLoading}
      destroyOnClose={true}
    >
      <Form name="basic" form={form} autoComplete="off">
        <Form.Item
          label="原始密码"
          name="pwd"
          rules={[{ required: true, message: "请输入您的原始密码！" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="新的密码"
          name="npwd"
          rules={[{ required: true, message: "请输入您的新密码！" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePwd;
