import React, { useEffect, useState, lazy, Suspense } from "react";
import request from "@/service";
import { Button, message, Popconfirm, Spin, Table } from "antd";
import SendPanel from "@/components/SendPanel";
import RecvPanel from "@/components/RecvPanel";
import CustHkPanel from "@/components/CustHkPanel";
import CustOrderNoPanel from "@/components/CustOrderNoPanel";
import CustOrderPackPanel from "@/components/CustOrderPackPanel";
import _ from "lodash";
import qs from "qs";

const HyOrderFlow = lazy(() => import("@/pages/hyOrderFlow"));

const Index: React.FC<any> = () => {
  const [loading, setLoading] = useState(false);
  const [showFlow, setShowFlow] = useState(false);
  const [curId, setCurId] = useState("");
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const clickOrderNo = (orderId: string) => {
    setCurId(orderId);
    setShowFlow(true);
  };
  const columns = [
    {
      title: "单号",
      dataIndex: "orderNo",
      key: "orderNo",
      ellipsis: true,
      width: 160,
      render: (_: any, record: any) => (
        <CustOrderNoPanel
          hyOrder={record}
          clickOrderNo={() => clickOrderNo(record.id)}
        />
      ),
    },
    {
      title: "始发站",
      dataIndex: "firstStoreName",
      key: "firstStoreName",
      width: 120,
      render: (_: any, record: any) => <CustOrderPackPanel hyOrder={record} />,
    },
    {
      title: "寄件人",
      dataIndex: "sendUserName",
      key: "sendUserName",
      render: (_: any, record: any) => <SendPanel hyOrder={record} />,
    },
    {
      title: "收件人",
      dataIndex: "rcvUserName",
      key: "rcvUserName",
      render: (_: any, record: any) => <RecvPanel hyOrder={record} />,
    },
    {
      title: "代收货款",
      dataIndex: "productMoney",
      key: "productMoney",
      render: (_: any, record: any) => <CustHkPanel hyOrder={record} />,
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
      width: 100,
    },
    {
      title: "申请结算",
      dataIndex: "id",
      key: "id",
      width: 100,
      render: (_: any, record: any) =>
        record.payStatus === "2" &&
        record.jsStatus === "1" && (
          <Popconfirm
            placement="topRight"
            title="确认申请结算货款吗？"
            onConfirm={() => {
              submitApplyJsOrder(record.id);
            }}
            okText="确定"
            cancelText="取消"
          >
            <Button type="primary" size="small">
              取消订单
            </Button>
          </Popconfirm>
        ),
    },
  ];
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/custJs/list",
        params: {
          ...pager,
          type: "1",
          jsStatus: "1",
          keyWords: "",
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  const submitApplyJsOrder = async (id: string) => {
    try {
      setLoading(true);
      const res: any = await request.post({
        url: "/hy/custJs/applyJsOrder",
        data: qs.stringify({
          id,
        }),
      });
      message.success("提交成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  return (
    <div className="container">
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          current: pager.pageNo,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <HyOrderFlow
          orderId={curId}
          open={showFlow}
          onCancel={() => {
            setShowFlow(false);
          }}
        />
      </Suspense>
    </div>
  );
};

export default Index;
