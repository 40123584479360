import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import storage from "@/utils/storage";
import { message, Modal } from "antd";
import type { HxRequestInterceptors, HxRequestConfig } from "./type";
import _ from "lodash";
import { log, info, warn, error } from "@log";

const DEFAULT_LOADING = true;
/** 数据请求成功 */
const SUCCESS_CODE = 0;
/** 注册信息审核未通过 */
const REGIST_ERR_CODE = 100;
/** token认证失败 */
const TOKEN_INVALID_CODE = 200;
/** 服务器异常 */
const SERVER_ERROR_CODE = 500;
/** 批量上传文件解析失败 */
const EXCEL_PAESE_ERROR_CODE = 666;
/** 产品库存不足，不能出库 */
const STORE_CK_ERROR_CODE = 777;
/** 工单收银错误码 */
const SHORDER_SHOUYIN_ERROR_CODE = 778;

const TOKEN_INVALID = "token认证失败，请重新登录！";
const NETWORK_ERROR = "网络或服务器异常，请稍后重试！";

class HxRequest {
  instance?: AxiosInstance;
  loadingInstance?: any;
  interceptors?: HxRequestInterceptors;
  showLoading: boolean; // 默认显示loading
  isDownload: boolean; // 是否下载文件：默认false

  constructor(config: HxRequestConfig) {
    this.instance = axios.create(config);
    this.showLoading = config.showLoading ?? DEFAULT_LOADING;
    this.isDownload = config.isDownload ?? false;
    this.interceptors = config.interceptors;
    // 解决每次ajax请求，对应的sessionId不一致的问题
    this.instance.defaults.withCredentials = true;

    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptor,
      this.interceptors?.requestInterceptorCatch
    );
    this.instance.interceptors.response.use(
      this.interceptors?.responseInterceptor,
      this.interceptors?.responseInterceptorCatch
    );
    // 所有实例都有的拦截器
    this.instance.interceptors.request.use(
      (conf: HxRequestConfig) => {
        if (conf.headers != null) {
          conf.headers["Access-Control-Allow-Origin"] = "*";
          conf.headers["Access-Control-Allow-Credentials"] = true;
        }
        log("request-->", conf);
        return conf;
      },
      (_err: any) => {
        console.log("request error-->", _err);
        return _err;
      }
    );
    this.instance.interceptors.response.use(
      (res: any) => {
        log("response-->", res);
        if (!res) {
          message.error(NETWORK_ERROR);
          return Promise.reject(NETWORK_ERROR);
        }
        if (res.name && res.name === "AxiosError") {
          if (
            (res.code === "ERR_BAD_RESPONSE" &&
              res.message === "Request failed with status code 504") ||
            (res.code === "ERR_NETWORK" && res.message === "Network Error")
          ) {
            message.error(NETWORK_ERROR);
            return Promise.reject(NETWORK_ERROR);
          }
          //   if (res.code === "ERR_NETWORK") {
          //     this.tokenInvalid();
          //     return Promise.reject(TOKEN_INVALID);
          //   }
          //   message.error(NETWORK_ERROR);
          //   return Promise.reject(NETWORK_ERROR);
        }
        if (this.isDownload) {
          return res.data;
        }
        const { code, msg } = res.data;
        if (code === undefined || code === null) {
          const responseURL = res.request.responseURL;
          if (!_.isEmpty(responseURL) && responseURL.indexOf("login") >= 0) {
            // 访问服务器接口，重定向到登陆页面
            this.tokenInvalid();
            return;
          }
        }
        if (code === SUCCESS_CODE || code === REGIST_ERR_CODE) {
          return res.data;
        } else if (
          code === EXCEL_PAESE_ERROR_CODE ||
          code === STORE_CK_ERROR_CODE ||
          code === SHORDER_SHOUYIN_ERROR_CODE
        ) {
          return res.data;
        } else if (code === SERVER_ERROR_CODE) {
          const responseURL = res.request.responseURL;
          if (!_.isEmpty(responseURL) && responseURL.indexOf("login") >= 0) {
            // 访问服务器接口，重定向到登陆页面
            this.tokenInvalid();
            return;
          }
          Modal.error({ title: "提示", content: msg });
          return Promise.reject(NETWORK_ERROR);
        } else if (code === TOKEN_INVALID_CODE) {
          this.tokenInvalid();
          return Promise.reject(TOKEN_INVALID);
        } else {
          Modal.error({ title: "提示", content: msg });
          return Promise.reject(msg ?? NETWORK_ERROR);
        }
      },
      (_err: any) => {
        console.log("response error-->", _err);
        if (this.showLoading) {
          this.loadingInstance?.close();
        }
        return _err;
      }
    );
  }

  async request<T>(config: HxRequestConfig<T>): Promise<T> {
    return await new Promise((resolve, reject) => {
      if (config.interceptors?.requestInterceptor != null) {
        // 执行单独请求的interceptor
        config = config.interceptors.requestInterceptor(config);
      }
      this.showLoading = config.showLoading ?? DEFAULT_LOADING;
      if (this.showLoading) {
        // TODO showLoading
      }
      this.instance
        ?.request<any, T>(config)
        .then((res) => {
          this.loadingInstance?.close();
          if (config.interceptors?.responseInterceptor != null) {
            res = config.interceptors.responseInterceptor(res);
          }
          return resolve(res);
        })
        .catch((error: any) => {
          this.loadingInstance?.close();
          return reject(error);
        });
    });
  }

  // DEMO：hxRequest.get<DataType>({url:'xxx', params: {}, showLoading:false})
  async get<T>(config: HxRequestConfig<T>): Promise<T> {
    return await this.request<T>({ ...config, method: "GET" });
  }

  // DEMO：hxRequest.post<DataType>({url:'xxx', data: {}, showLoading:false})
  async post<T>(config: HxRequestConfig<T>): Promise<T> {
    return await this.request<T>({ ...config, method: "POST" });
  }

  async delete<T>(config: HxRequestConfig<T>): Promise<T> {
    return await this.request<T>({ ...config, method: "DELETE" });
  }

  async patch<T>(config: HxRequestConfig<T>): Promise<T> {
    return await this.request<T>({ ...config, method: "PATCH" });
  }

  tokenInvalid(): void {
    message.error(TOKEN_INVALID);
    storage.clear();
    this.goHome();
  }
  goHome(): void {
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  }
}

export default HxRequest;
