import request from "@/service";
import { Descriptions, Spin } from "antd";
import React, { useEffect, useState } from "react";

const Index: React.FC<any> = () => {
  const [loading, setLoading] = useState(false);
  const [userAccount, setUserAccount] = useState<any>({});
  useEffect(() => {
    loadData();
  }, []);
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/freightTx/getAccountInfo",
        params: {},
      });
      setUserAccount(res.result);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  return (
    <Spin spinning={loading}>
      <div className="ml-10 mr-10 mb-10">
        <Descriptions bordered size="default" column={1}>
          <Descriptions.Item label="公司名称">
            {userAccount?.company}
          </Descriptions.Item>
          <Descriptions.Item label="开户行">
            {userAccount?.payBankName}
          </Descriptions.Item>
          <Descriptions.Item label="开户行省市">
            <>
              <span>{userAccount?.payProvinceName}</span>
              <span>{userAccount?.payCityName}</span>
            </>
          </Descriptions.Item>
          <Descriptions.Item label="开户人姓名">
            {userAccount?.payAccountName}
          </Descriptions.Item>
          <Descriptions.Item label="银行账号">
            {userAccount?.payAccountNo}
          </Descriptions.Item>
          <Descriptions.Item label="手机号">
            {userAccount?.phone}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </Spin>
  );
};
export default Index;
