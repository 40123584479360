import React from "react";
import _ from "lodash";

const Index: React.FC<any> = (props: any) => {
  let showCompany = false;
  if (
    !_.isEmpty(props.hyOrder.sendCompany) ||
    !_.isEmpty(props.hyOrder.sendCompany)
  ) {
    showCompany = true;
  }
  return (
    <div>
      {showCompany && (
        <div>
          {!_.isEmpty(props.hyOrder.sendCompany) && (
            <span>{props.hyOrder.sendCompany}</span>
          )}
          {!_.isEmpty(props.hyOrder.sendLine) && (
            <span>({props.hyOrder.sendLine})</span>
          )}
        </div>
      )}
      <div>
        <span>{props.hyOrder.sendUserName}</span>
        <span>{props.hyOrder.sendPhone}</span>
      </div>
      <div>
        <span>{props.hyOrder.sendProvince}</span>
        <span>{props.hyOrder.sendCity}</span>
        <span>{props.hyOrder.sendArea}</span>
        <span>{props.hyOrder.sendAddress}</span>
      </div>
    </div>
  );
};

export default Index;
