import React, { useEffect, useState } from "react";
import request from "@/service";
import { Table, Tag } from "antd";
import _ from "lodash";

const Index: React.FC<any> = () => {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const columns = [
    {
      title: "单号",
      dataIndex: "orderNo",
      key: "orderNo",
    },
    {
      title: "来源",
      dataIndex: "fromType",
      key: "fromType",
      render: (_: any, record: any) =>
        record.fromType === "0"
          ? "运费"
          : record.fromType === "1"
          ? "提现"
          : record.fromType === "2"
          ? "货款"
          : record.fromType === "3"
          ? "跑腿费"
          : "",
    },
    {
      title: "变化前",
      dataIndex: "fbalance",
      key: "fbalance",
      render: (_: any, record: any) => (
        <span className="text-price">{record.fbalance}</span>
      ),
    },
    {
      title: "变化金额",
      dataIndex: "money",
      key: "money",
      render: (_: any, record: any) =>
        record.money === 0 ? (
          "0"
        ) : record.doType === "0" ? (
          <span className="red-price">+{record.money}</span>
        ) : record.doType === "1" ? (
          <span className="green-price">-{record.money}</span>
        ) : (
          "-"
        ),
    },
    {
      title: "变化后",
      dataIndex: "ebalance",
      key: "ebalance",
      render: (_: any, record: any) => (
        <span className="text-price">{record.ebalance}</span>
      ),
    },
    {
      title: "增减",
      dataIndex: "doType",
      key: "doType",
      render: (_: any, record: any) =>
        record.doType === "0" ? (
          <Tag color="red">增加</Tag>
        ) : record.doType === "1" ? (
          <Tag color="green">减少</Tag>
        ) : (
          "-"
        ),
    },
    {
      title: "说明",
      dataIndex: "typeNote",
      key: "typeNote",
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "下单日期",
      dataIndex: "createDate",
      key: "createDate",
    },
  ];
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/userBalanceMx/list",
        params: {
          ...pager,
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  return (
    <div className="container">
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          current: pager.pageNo,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
    </div>
  );
};

export default Index;
