import React, { useEffect, useState, lazy, Suspense } from "react";
import request from "@/service";
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Modal,
  Select,
  Spin,
  Table,
  Tag,
} from "antd";
import _ from "lodash";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import qs from "qs";

const { Option } = Select;

const Index: React.FC<any> = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const columns = [
    {
      title: "单号",
      dataIndex: "txNo",
      key: "txNo",
      render: (_: any, record: any) => (
        <>
          <div>{record.txNo}</div>
          <div>{record.deptName}</div>
        </>
      ),
    },
    {
      title: "申请人",
      dataIndex: "userName",
      key: "userName",
      render: (_: any, record: any) => (
        <>
          <div>{record.userName}</div>
          {record.userType === "1" && <Tag color="green">自寄客户</Tag>}
          {record.userType === "2" && <Tag color="blue">物流公司</Tag>}
        </>
      ),
    },
    {
      title: "提现金额",
      dataIndex: "txMoney",
      key: "txMoney",
      render: (_: any, record: any) => (
        <span className="red-price">{record.txMoney}</span>
      ),
    },
    {
      title: "手续费",
      dataIndex: "sxMoney",
      key: "sxMoney",
      render: (_: any, record: any) =>
        record.sxMoney === 0 ? (
          "0"
        ) : (
          <span className="green-price">-{record.sxMoney}</span>
        ),
    },
    {
      title: "到账金额",
      dataIndex: "dzMoney",
      key: "dzMoney",
      render: (_: any, record: any) => (
        <span className="red-price">{record.dzMoney}</span>
      ),
    },
    {
      title: "开户行",
      dataIndex: "bankName",
      key: "bankName",
      render: (_: any, record: any) => (
        <>
          <div>{record.bankName}</div>
          <div>
            {record.provinceName}
            {record.cityName}
          </div>
        </>
      ),
    },
    {
      title: "持卡人",
      dataIndex: "cardName",
      key: "cardName",
      render: (_: any, record: any) => (
        <>
          <div>{record.cardName}</div>
          <div>{record.cardNo}</div>
        </>
      ),
    },
    {
      title: "提现类型",
      dataIndex: "txType",
      key: "txType",
      render: (_: any, record: any) =>
        record.txType === "1" ? (
          <Tag color="green">运费提现</Tag>
        ) : record.txType === "2" ? (
          <Tag color="blue">货款提现</Tag>
        ) : (
          "-"
        ),
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (_: any, record: any) =>
        record.doStatus === "0" ? (
          <Tag color="green">申请中</Tag>
        ) : record.doStatus === "1" ? (
          <Tag color="blue">已完成</Tag>
        ) : (
          "-"
        ),
    },
    {
      title: "申请日期",
      dataIndex: "createDate",
      key: "createDate",
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      render: (_: any, record: any) =>
        record.status === "2" ? (
          "-"
        ) : (
          <Button
            size="small"
            type="primary"
            onClick={() => clickTx(record.id)}
          >
            同意提现
          </Button>
        ),
    },
  ];
  const onFinish = (values: any) => {
    if (pager.pageNo === 1) {
      loadData();
    } else {
      const tmpPager = JSON.parse(JSON.stringify(pager));
      tmpPager.pageNo = 1;
      setPager(tmpPager);
    }
  };
  const clickTx = (txId: string) => {
    Modal.confirm({
      title: "提示",
      icon: <ExclamationCircleOutlined />,
      content: "是否同意提现？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        submitAgreeTx(txId);
      },
      onCancel() {},
    });
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/userTx/list",
        params: {
          ...pager,
          ...form.getFieldsValue(["txType", "status", "userType", "keyWords"]),
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  const submitAgreeTx = async (txId: string) => {
    try {
      const res: any = await request.post({
        url: "/hy/userTx/agreeTx",
        data: qs.stringify({
          id: txId,
        }),
      });
      message.success("提交成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
  };
  return (
    <div className="container">
      <Form
        form={form}
        name="horizontal_login"
        layout="inline"
        onFinish={onFinish}
        initialValues={{
          txType: "",
          status: "",
          userType: "",
          keyWords: "",
        }}
        style={{ marginBottom: "8px" }}
      >
        <Form.Item name="txType" label="">
          <Select placeholder="提现类型">
            <Option value="">提现类型</Option>
            <Option value="1">运费</Option>
            <Option value="2">货款</Option>
          </Select>
        </Form.Item>
        <Form.Item name="status" label="">
          <Select placeholder="提现状态">
            <Option value="">提现状态</Option>
            <Option value="1">待处理</Option>
            <Option value="2">已完成</Option>
            <Option value="3">失败</Option>
          </Select>
        </Form.Item>
        <Form.Item name="userType" label="">
          <Select placeholder="用户类型">
            <Option value="">用户类型</Option>
            <Option value="1">自寄客户</Option>
            <Option value="2">物流公司</Option>
          </Select>
        </Form.Item>
        <Form.Item name="keyWords">
          <Input allowClear placeholder="姓名、电话等搜索" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="search-btn-bg">
            查询
          </Button>
        </Form.Item>
      </Form>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          current: pager.pageNo,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
    </div>
  );
};

export default Index;
