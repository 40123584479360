import SingleSearch from "@/components/SingleSearch";
import request from "@/service";
import {
  DeleteOutlined,
  EditOutlined,
  FormOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import { Button, message, Popconfirm, Space, Spin, Table, Tag } from "antd";
import qs from "qs";
import _ from "lodash";
import React, { Suspense, useEffect, useState, lazy } from "react";

const AddAddress = lazy(() => import("./AddAddress"));
const EditAddress = lazy(() => import("./EditAddress"));
const HyAddressImage = lazy(() => import("./HyAddressImage"));
const AccountInfo = lazy(() => import("@/components/AccountInfo"));
const ChooseAccount = lazy(() => import("@/components/ChooseAccount"));
const AllMap = lazy(() => import("@/components/AllMap"));
const SingleMap = lazy(() => import("@/components/SingleMap"));

const HyAddress = () => {
  const [curId, setCurId] = useState("");
  const [curItem, setCurItem] = useState({});
  const [curUserAccountId, setCurUserAccountId] = useState("");
  const [showChooseAccount, setShowChooseAccount] = useState(false);
  const [showAllMap, setShowAllMap] = useState(false);
  const [showSingleMap, setShowSingleMap] = useState(false);
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  let _keyWords = "";
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const refresh = () => {
    setShowAdd(false);
    setShowEdit(false);
    setShowChooseAccount(false);
    loadData();
  };

  const clickChooseAccount = (id: string) => {
    setCurId(id);
    setShowChooseAccount(true);
  };

  const columns = [
    {
      title: "姓名",
      dataIndex: "name",
      width: 160,
      key: "name",
      render: (_prams: any, record: any) => (
        <>
          <span>{record.name}</span>
          {!_.isEmpty(record.company) && (
            <span className="ml-1">({record.company})</span>
          )}
        </>
      ),
    },
    {
      title: "收款账户",
      width: 120,
      dataIndex: "userAccountName",
      key: "userAccountName",
      render: (_prams: any, record: any) =>
        _.isEmpty(record.userAccountName) ? (
          <Button
            type="link"
            size="small"
            onClick={() => clickChooseAccount(record.id)}
          >
            添加
          </Button>
        ) : (
          <Space size="small">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setCurUserAccountId(record.userAccountId);
                setShowAccountInfo(true);
              }}
            >
              {record.userAccountName}
            </a>
            <Button
              type="link"
              size="small"
              icon={<EditOutlined />}
              onClick={() => clickChooseAccount(record.id)}
            ></Button>
          </Space>
        ),
    },
    {
      title: "联系电话",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "地址",
      dataIndex: "address",
      key: "address",
      width: 100,
      ellipsis: true,
      render: (_: any, record: any) => (
        <span>
          {record.provinceName}
          {record.cityName}
          {record.areaName}
          <br />
          {record.address}
        </span>
      ),
    },
    {
      title: "创建人",
      dataIndex: "createByName",
      key: "createByName",
    },
    {
      title: "创建日期",
      dataIndex: "createDate",
      key: "createDate",
    },
    {
      title: "更新人",
      dataIndex: "updateByName",
      key: "updateByName",
    },
    {
      title: "更新日期",
      dataIndex: "updateDate",
      key: "updateDate",
    },
    {
      title: "片区",
      dataIndex: "line",
      key: "line",
    },
    {
      title: "绑定客户",
      dataIndex: "custDeptName",
      key: "custDeptName",
    },
    {
      title: "基准运费",
      dataIndex: "freight",
      key: "freight",
      render: (_: any, record: any) => (
        <span className="text-price">{record.freight}</span>
      ),
    },
    {
      title: "类别",
      dataIndex: "categoryType",
      key: "categoryType",
      render: (_: any, record: any) =>
        record.categoryType === "1" ? (
          <Tag color="green">修理厂</Tag>
        ) : record.categoryType === "2" ? (
          <Tag color="blue">汽配商</Tag>
        ) : record.categoryType === "3" ? (
          <Tag color="red">物流公司</Tag>
        ) : (
          "-"
        ),
    },
    {
      title: "坐标",
      dataIndex: "location",
      key: "location",
      render: (_: any, record: any) =>
        record.hasLonLat ? (
          <Button
            type="link"
            onClick={() => {
              setCurItem(record);
              setShowSingleMap(true);
            }}
          >
            查看
          </Button>
        ) : (
          <Button
            type="link"
            danger
            onClick={() => {
              setCurItem(record);
              setShowSingleMap(true);
            }}
          >
            去标记
          </Button>
        ),
    },
    // {
    //   title: "主营项目",
    //   dataIndex: "zyxm",
    //   key: "zyxm",
    // },
    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
      ellipsis: true,
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      render: (_: any, record: any) => (
        <Space size="small">
          <Button
            type="primary"
            size="small"
            title="图片"
            icon={<PictureOutlined />}
            onClick={() => clickImage(record.id)}
          >
            {record.imageCount}
          </Button>
          <Button
            type="primary"
            size="small"
            title="编辑"
            icon={<FormOutlined />}
            onClick={() => clickEdit(record.id)}
          />
          <Popconfirm
            title="确定要删除地址吗？"
            placement="topRight"
            onConfirm={() => deleteAddress(record.id)}
          >
            <Button
              type="primary"
              danger
              size="small"
              title="删除"
              loading={delLoading}
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const clickImage = (id: string) => {
    setCurId(id);
    setShowImage(true);
  };
  const clickEdit = (id: string) => {
    setCurId(id);
    setShowEdit(true);
  };
  const doSearch = (keyWords: string) => {
    _keyWords = keyWords;
    loadData();
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/hyAddress/list",
        params: {
          ...pager,
          keyWords: _keyWords,
        },
      });
      if (res.rows && res.rows.length > 0) {
        res.rows.forEach((item: any) => {
          item.hasLonLat =
            !_.isEmpty(item.latitude) && !_.isEmpty(item.longitude);
        });
      }
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  const deleteAddress = async (id: string) => {
    try {
      setDelLoading(true);
      const res: any = await request.post({
        url: "/hy/hyAddress/update",
        data: qs.stringify({
          id,
          delFlag: "1",
        }),
      });
      message.success("删除成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
    setDelLoading(false);
  };
  return (
    <div className="container">
      <div className="flex mb-2">
        <SingleSearch placeholder="姓名、电话等" doSearch={doSearch} />
        <Button type="primary" onClick={() => setShowAdd(true)}>
          新建
        </Button>
        <Button
          className="ml-2"
          type="primary"
          onClick={() => setShowAllMap(true)}
        >
          全部地图
        </Button>
      </div>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <AddAddress
          open={showAdd}
          refresh={refresh}
          onCancel={() => {
            setShowAdd(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <EditAddress
          addressId={curId}
          open={showEdit}
          refresh={refresh}
          onCancel={() => {
            setShowEdit(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <HyAddressImage
          addressId={curId}
          open={showImage}
          refresh={refresh}
          onCancel={() => {
            setShowImage(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <AccountInfo
          userAccountId={curUserAccountId}
          open={showAccountInfo}
          onCancel={() => {
            setShowAccountInfo(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <ChooseAccount
          addressId={curId}
          refresh={refresh}
          open={showChooseAccount}
          onCancel={() => {
            setShowChooseAccount(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <AllMap
          deptId=""
          open={showAllMap}
          onCancel={() => {
            setShowAllMap(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <SingleMap
          hyAddress={curItem}
          open={showSingleMap}
          refresh={loadData}
          onCancel={() => {
            setShowSingleMap(false);
          }}
        />
      </Suspense>
    </div>
  );
};

export default HyAddress;
