import SingleSearch from "@/components/SingleSearch";
import request from "@/service";
import {
  FormOutlined,
  KeyOutlined,
  BorderlessTableOutlined,
  OrderedListOutlined,
  AimOutlined,
  TeamOutlined,
  IdcardOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Button, message, Popconfirm, Space, Spin, Table, Tag } from "antd";
import qs from "qs";
import _ from "lodash";
import React, { Suspense, useEffect, useState, lazy } from "react";

const AddWuliu = lazy(() => import("./AddWuliu"));
const EditWuliu = lazy(() => import("./EditWuliu"));
const WuliuChangePwd = lazy(() => import("./WuliuChangePwd"));
const WuliuOrder = lazy(() => import("./WuliuOrder"));
const WuliuStore = lazy(() => import("./WuliuStore"));
const WuliuLine = lazy(() => import("./WuliuLine"));
const WuliuUsers = lazy(() => import("./WuliuUsers"));
const WuliuAccount = lazy(() => import("./WuliuAccount"));
const WuliuAddress = lazy(() => import("./WuliuAddress"));
const WuliuAddressAll = lazy(() => import("./WuliuAddressAll"));

const Index = () => {
  const [curId, setCurId] = useState("");
  const [curUserId, setCurUserId] = useState("");
  const [curDeptId, setCurDeptId] = useState("");
  const [showChangePwd, setShowChangePwd] = useState(false);
  const [showWuliuOrder, setShowWuliuOrder] = useState(false);
  const [showWuliuStore, setShowWuliuStore] = useState(false);
  const [showWuliuLine, setShowWuliuLine] = useState(false);
  const [showWuliuAccount, setShowWuliuAccount] = useState(false);
  const [showWuliuAddress, setShowWuliuAddress] = useState(false);
  const [showWuliuAddressAll, setShowWuliuAddressAll] = useState(false);
  const [showWuliuUsers, setShowWuliuUsers] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  let _keyWords = "";
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const refresh = () => {
    setShowAdd(false);
    setShowEdit(false);
    loadData();
  };
  const columns = [
    {
      title: "物流公司",
      dataIndex: "deptName",
      key: "deptName",
      render: (_: any, record: any) => (
        <>
          <span>{record.deptName}</span>
          <br />
          <span>{record.createDate}</span>
        </>
      ),
    },
    {
      title: "管理员姓名",
      dataIndex: "userName",
      width: 150,
      key: "userName",
      render: (_: any, record: any) => (
        <>
          <span>{record.userName}</span>
          <br />
          <span>{record.userCode}</span>
        </>
      ),
    },
    {
      title: "手机号",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "地址",
      dataIndex: "address",
      key: "address",
      ellipsis: true,
      render: (_: any, record: any) => (
        <>
          <span>
            {record.provinceName}
            {record.cityName}
            {record.areaName}
          </span>
          <br />
          <span>{record.address}</span>
        </>
      ),
    },
    {
      title: "虚拟标记",
      dataIndex: "virtualFlag",
      width: 100,
      key: "virtualFlag",
      render: (_prams: any, record: any) => (
        <>
          {record.virtualFlag === "0" && <Tag color="green">正常物流</Tag>}
          {record.virtualFlag === "1" && <Tag color="red">虚拟物流</Tag>}
        </>
      ),
    },
    {
      title: "状态",
      dataIndex: "delFlag",
      key: "delFlag",
      width: 100,
      render: (_prams: any, record: any) => (
        <>
          {record.delFlag === "0" && <Tag color="green">正常</Tag>}
          {record.delFlag === "1" && <Tag color="red">禁用</Tag>}
        </>
      ),
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "操作",
      dataIndex: "id",
      width: 160,
      key: "id",
      render: (_: any, record: any) => (
        <>
          <div className="mb-1">
            <Space size={5}>
              <Button
                type="primary"
                size="small"
                title="订单列表"
                icon={<OrderedListOutlined />}
                onClick={() => {
                  setCurDeptId(record.deptId);
                  setShowWuliuOrder(true);
                }}
              />
              <Button
                type="primary"
                size="small"
                title="站点列表"
                icon={<AimOutlined />}
                onClick={() => {
                  setCurDeptId(record.deptId);
                  setShowWuliuStore(true);
                }}
              />
              <Button
                type="primary"
                size="small"
                title="线路列表"
                icon={<BorderlessTableOutlined />}
                onClick={() => {
                  setCurDeptId(record.deptId);
                  setShowWuliuLine(true);
                }}
              />
              <Button
                type="primary"
                size="small"
                title="员工列表"
                icon={<TeamOutlined />}
                onClick={() => {
                  setCurDeptId(record.deptId);
                  setShowWuliuUsers(true);
                }}
              />
            </Space>
          </div>
          <div>
            <Space size={5}>
              <Button
                type="primary"
                size="small"
                title="收款账户"
                icon={<IdcardOutlined />}
                onClick={() => {
                  setCurId(record.id);
                  setShowWuliuAccount(true);
                }}
              />
              <Button
                type="primary"
                size="small"
                title="地址列表"
                icon={<SolutionOutlined />}
                onClick={() => {
                  setCurDeptId(record.deptId);
                  setShowWuliuAddress(true);
                }}
              />
              <Button
                type="primary"
                size="small"
                title="编辑"
                icon={<FormOutlined />}
                onClick={() => clickEdit(record)}
              />
              <Button
                type="primary"
                size="small"
                icon={<KeyOutlined />}
                onClick={() => clickChangePwd(record)}
              />
            </Space>
          </div>
        </>
      ),
    },
  ];
  const clickChangePwd = (item: any) => {
    setCurUserId(item.userId);
    setShowChangePwd(true);
  };
  const clickEdit = (item: any) => {
    setCurId(item.id);
    setCurUserId(item.userId);
    setShowEdit(true);
  };
  const doSearch = (keyWords: string) => {
    _keyWords = keyWords;
    loadData();
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/hyWuliu/list",
        params: {
          ...pager,
          keyWords: _keyWords,
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  return (
    <div className="container">
      <div className="flex mb-2">
        <SingleSearch placeholder="姓名、电话等" doSearch={doSearch} />
        <Button type="primary" onClick={() => setShowAdd(true)}>
          新建
        </Button>
        <Button
          className="ml-2"
          type="primary"
          onClick={() => setShowWuliuAddressAll(true)}
        >
          地址簿
        </Button>
      </div>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <AddWuliu
          open={showAdd}
          refresh={refresh}
          onCancel={() => {
            setShowAdd(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <EditWuliu
          orgId={curId}
          userId={curUserId}
          open={showEdit}
          refresh={refresh}
          onCancel={() => {
            setShowEdit(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <WuliuChangePwd
          userId={curUserId}
          open={showChangePwd}
          onCancel={() => {
            setShowChangePwd(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <WuliuOrder
          deptId={curDeptId}
          open={showWuliuOrder}
          onCancel={() => {
            setShowWuliuOrder(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <WuliuStore
          deptId={curDeptId}
          open={showWuliuStore}
          onCancel={() => {
            setShowWuliuStore(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <WuliuLine
          deptId={curDeptId}
          open={showWuliuLine}
          onCancel={() => {
            setShowWuliuLine(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <WuliuUsers
          deptId={curDeptId}
          open={showWuliuUsers}
          onCancel={() => {
            setShowWuliuUsers(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <WuliuAccount
          orgId={curId}
          refresh={() => {
            loadData();
            setShowWuliuAccount(false);
          }}
          open={showWuliuAccount}
          onCancel={() => {
            setShowWuliuAccount(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <WuliuAddress
          deptId={curDeptId}
          open={showWuliuAddress}
          onCancel={() => {
            setShowWuliuAddress(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <WuliuAddressAll
          open={showWuliuAddressAll}
          onCancel={() => {
            setShowWuliuAddressAll(false);
          }}
        />
      </Suspense>
    </div>
  );
};

export default Index;
