import React, { ReactNode } from "react";
import _ from "lodash";
import { Button, Popconfirm, Tag, TagType } from "antd";

const Index: React.FC<any> = (props: any) => {
  return (
    <div>
      <div>
        <span className="text-price text-red-500">{props.hyOrder.freight}</span>
        <span className="ml-1">
          {props.hyOrder.freightType === "1" ? "寄方付" : "收方付"}
        </span>
      </div>
      {props.hyOrder.ptType !== "1" && (
        <div>
          {getStatusName(props.hyOrder)}
          <div className="mt-1">
            {props.hyOrder.freightFinanceStatus === "1" &&
              props.confirmFreight && (
                <Popconfirm
                  placement="topRight"
                  title="确认已收到运费吗？"
                  onConfirm={props.confirmFreight}
                  okText="确定"
                  cancelText="取消"
                >
                  <Button type="primary" size="small">
                    确认运费
                  </Button>
                </Popconfirm>
              )}
            {props.hyOrder.freightFinanceStatus === "2" && (
              <Tag>运费已确认</Tag>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;

const getStatusName = (hyOrder: any): ReactNode => {
  if (hyOrder.freightStatus === "1") {
    return <Tag color="red">待支付</Tag>;
  }
  if (hyOrder.freightStatus === "2") {
    let str = "线下付款";
    if (hyOrder.freightPayType === "1") {
      str = "运费劵";
    } else if (hyOrder.freightPayType === "2") {
      str = "月结";
    }
    <Tag color="green">{str}</Tag>;
  } else if (hyOrder.freightStatus === "3") {
    return <Tag color="green">线上支付</Tag>;
  }
  let str = "";
  if (hyOrder.freightStatus === "4") {
    str = "线下已结算";
    if (hyOrder.freightPayType === "1") {
      str = "运费劵已结算";
    } else if (hyOrder.freightPayType === "2") {
      str = "月结已结算";
    }
  } else if (hyOrder.freightStatus === "5") {
    str = "线上已结算";
  }
  return _.isEmpty(str) ? "" : <Tag color="blue">{str}</Tag>;
};
