import React from "react";
import ReactDOM from "react-dom/client";
import "@/assets/styles/index.css";
import "antd-colorful/index.css";
import MyRouter from "@/router";
import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'
// import "antd/dist/reset.css"; // or 'antd/dist/antd.less'
// import reportWebVitals from "./reportWebVitals";
// import "dayjs/locale/zh-cn";
import moment from "moment";
import "moment/locale/zh-cn";
// 在需要用到的 组件文件中引入中文语言包
import locale from "antd/es/locale/zh_CN";
// 引入国际化配置
import { ConfigProvider } from "antd";
moment.locale("zh-cn"); // 注意这里设置 moment 必须放在有 import 的后面。

const root = ReactDOM.createRoot(document.getElementById("root"));
console.log("process.env.NODE_ENV-->", process.env.NODE_ENV);
root.render(
  // <React.StrictMode>
  <ConfigProvider locale={locale}>
    <MyRouter />
  </ConfigProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
