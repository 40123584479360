import {
  Button,
  DatePicker,
  Form,
  message,
  Modal,
  Space,
  Spin,
  Table,
} from "antd";
import React, { useEffect, useState, lazy, Suspense } from "react";
import request from "@/service";
import moment from "moment";
import _ from "lodash";
import qs from "qs";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const Record = lazy(() => import("./Record"));
const FinanceOrder = lazy(() => import("./FinanceOrder"));
const FinanceDay = lazy(() => import("./FinanceDay"));

const FinanceConfirm = () => {
  const [form] = Form.useForm();
  const [showOrder, setShowOrder] = useState(false);
  const [showRecord, setShowRecord] = useState(false);
  const [showFinanceDay, setShowFinanceDay] = useState(false);
  const [userId, setUserId] = useState("");
  const [month, setMonth] = useState(moment().format("YYYY-MM"));
  const [orderType, setOrderType] = useState(""); // 1运费 2货款 3跑腿
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  useEffect(() => {
    loadData();
  }, [pager]);
  useEffect(() => {
    loadData();
  }, [month]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const onFinish = (values: any) => {
    const newMonth = values["month"].format("YYYY-MM");
    if (newMonth !== month) {
      setMonth(newMonth);
    } else {
      loadData();
    }
  };
  const confirmFreight = (userId: string) => {
    Modal.confirm({
      title: "提示",
      icon: <ExclamationCircleOutlined />,
      content: "确认线下已收到运费吗？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        submitConfirmFreight(userId);
      },
      onCancel() {},
    });
  };
  const confirmHk = (userId: string) => {
    Modal.confirm({
      title: "提示",
      icon: <ExclamationCircleOutlined />,
      content: "确认线下已收到货款吗？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        submitConfirmHk(userId);
      },
      onCancel() {},
    });
  };
  const confirmPtMoney = (userId: string) => {
    Modal.confirm({
      title: "提示",
      icon: <ExclamationCircleOutlined />,
      content: "确认线下已收到跑腿费吗？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        submitConfirmPtMoney(userId);
      },
      onCancel() {},
    });
  };
  const confirmAll = (userId: string) => {
    Modal.confirm({
      title: "提示",
      icon: <ExclamationCircleOutlined />,
      content: "确认线下已收到钱款（货款、运费、跑腿费）吗？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        submitConfirmAll(userId);
      },
      onCancel() {},
    });
  };
  const columns = [
    {
      title: "姓名",
      dataIndex: "userName",
      key: "userName",
      ellipsis: true,
      width: 160,
    },
    {
      title: "运费",
      dataIndex: "freightCountUn",
      key: "freightCountUn",
      ellipsis: true,
      render: (_: any, record: any) =>
        record.freightCountUn <= 0 ? (
          "-"
        ) : (
          <div>
            <div>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  setUserId(record.userId);
                  setOrderType("1");
                  setShowOrder(true);
                }}
              >
                {record.freightCountUn}单
              </Button>
              <span className="text-price ml-2">{record.freightSumUn}</span>
              <span>，需收款：</span>
              <span className="text-price">{record.freightMoney}</span>
            </div>
            <div className="mt-1">
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  confirmFreight(record.userId);
                }}
              >
                确认运费
              </Button>
            </div>
          </div>
        ),
    },
    {
      title: "运费券",
      dataIndex: "freightQuanCount",
      key: "freightQuanCount",
      ellipsis: true,
      render: (_: any, record: any) =>
        record.freightQuanCount <= 0 ? (
          "-"
        ) : (
          <>
            <div>{record.freightQuanCount}单</div>
            <div className="text-price">{record.freightQuanSum}</div>
          </>
        ),
    },
    {
      title: "包月",
      dataIndex: "freightYueCount",
      key: "freightYueCount",
      ellipsis: true,
      render: (_: any, record: any) =>
        record.freightYueCount <= 0 ? (
          "-"
        ) : (
          <>
            <div>{record.freightYueCount}单</div>
            <div className="text-price">{record.freightYueSum}</div>
          </>
        ),
    },
    {
      title: "代收货款",
      dataIndex: "orderUn",
      key: "orderUn",
      ellipsis: true,
      render: (_: any, record: any) =>
        record.orderUnNum <= 0 ? (
          "-"
        ) : (
          <div>
            <div>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  setUserId(record.userId);
                  setOrderType("2");
                  setShowOrder(true);
                }}
              >
                {record.orderUnNum}单
              </Button>
              <span className="text-price ml-2">{record.orderMoneyUn}</span>
            </div>
            <div className="mt-1">
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  confirmHk(record.userId);
                }}
              >
                确认货款
              </Button>
            </div>
          </div>
        ),
    },
    {
      title: "跑腿费",
      dataIndex: "ptCountUn",
      key: "ptCountUn",
      ellipsis: true,
      render: (_: any, record: any) =>
        record.ptCountUn <= 0 ? (
          "-"
        ) : (
          <div>
            <div>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  setUserId(record.userId);
                  setOrderType("3");
                  setShowOrder(true);
                }}
              >
                {record.ptCountUn}单
              </Button>
              <span className="text-price ml-2">{record.ptMoneyUn}</span>
            </div>
            <div className="mt-1">
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  confirmPtMoney(record.userId);
                }}
              >
                确认跑腿费
              </Button>
            </div>
          </div>
        ),
    },
    {
      title: "总计",
      dataIndex: "totalCount",
      key: "totalCount",
      ellipsis: true,
      render: (_: any, record: any) =>
        record.totalCount <= 0 ? (
          "-"
        ) : (
          <div>
            <div>
              <span>{record.totalCount}单</span>
              <span className="text-price ml-2">{record.totalMoney}</span>
            </div>
            <div>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  confirmAll(record.userId);
                }}
              >
                一键确认
              </Button>
            </div>
          </div>
        ),
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (_: any, record: any) => (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            setUserId(record.userId);
            setShowFinanceDay(true);
          }}
        >
          按天查看
        </Button>
      ),
    },
  ];
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/hyStatistics/financeList",
        params: {
          ...pager,
          month,
        },
      });
      if (res?.rows && res?.rows.length > 0) {
        res.rows.forEach((item: any) => {
          let money = item.freightSumUn;
          if (item.freightQuanCount > 0) {
            money = _.subtract(money, item.freightQuanSum);
            money = _.round(money, 2);
          }
          if (item.freightYueCount > 0) {
            money = _.subtract(money, item.freightYueSum);
            money = _.round(money, 2);
          }
          item.freightMoney = money;
          item.totalCount =
            item.orderUnNum + item.freightCountUn + item.ptCountUn;
          item.totalMoney =
            item.orderMoneyUn + item.freightSumUn + item.ptMoneyUn;
        });
      }
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  const submitConfirmFreight = async (userId: string) => {
    try {
      const res: any = await request.post({
        url: "/hy/hyOrder/freightFinance",
        data: qs.stringify({
          userId,
          month,
        }),
      });
      message.success("提交成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
  };
  const submitConfirmHk = async (userId: string) => {
    try {
      const res: any = await request.post({
        url: "/hy/hyOrder/freightFinance",
        data: qs.stringify({
          userId,
          month,
        }),
      });
      message.success("提交成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
  };
  const submitConfirmPtMoney = async (userId: string) => {
    try {
      const res: any = await request.post({
        url: "/hy/hyOrder/ptFinance",
        data: qs.stringify({
          userId,
          month,
        }),
      });
      message.success("提交成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
  };
  const submitConfirmAll = async (userId: string) => {
    try {
      const res: any = await request.post({
        url: "/hy/hyOrder/allFinance",
        data: qs.stringify({
          userId,
          month,
        }),
      });
      message.success("提交成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
  };
  return (
    <>
      <div className="container">
        <div className="flex mb-2">
          <Form
            layout="inline"
            onFinish={onFinish}
            form={form}
            initialValues={{ month: moment() }}
          >
            <Form.Item name="month">
              <DatePicker picker="month" style={{ width: "200px" }} />
            </Form.Item>
            <Form.Item>
              <Space size="small">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="search-btn-bg"
                >
                  查询
                </Button>
                <Button type="primary" onClick={() => setShowRecord(true)}>
                  确认记录
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
        <Table
          size="small"
          tableLayout="auto"
          loading={loading}
          bordered
          rowKey="id"
          dataSource={tableData}
          pagination={{
            total: total,
            showTotal: (t) => {
              return `共${t}条数据`;
            },
            size: "default",
          }}
          columns={columns}
          onChange={tableChange}
        />
      </div>
      <Suspense fallback={<Spin />}>
        <Record
          open={showRecord}
          onCancel={() => {
            setShowRecord(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <FinanceOrder
          open={showOrder}
          userId={userId}
          orderType={orderType}
          month={month}
          onCancel={() => {
            setShowOrder(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <FinanceDay
          open={showFinanceDay}
          userId={userId}
          month={month}
          refresh={() => {
            if (pager.pageNo !== 1) {
              const tpager = JSON.parse(JSON.stringify(pager));
              tpager.pageNo = 1;
              setPager(tpager);
            } else {
              loadData();
            }
          }}
          onCancel={() => {
            setShowFinanceDay(false);
          }}
        />
      </Suspense>
    </>
  );
};

export default FinanceConfirm;
