import React, { Suspense, useEffect, useState, lazy } from "react";
import request from "@/service";
import { Button, Form, Select, Spin, Table, DatePicker } from "antd";
import moment from "moment";
import _ from "lodash";

const NoSettlementOrder = lazy(() => import("./NoSettlementOrder"));
const { RangePicker } = DatePicker;
const { Option } = Select;

const Index: React.FC<any> = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [userId, setUserId] = useState("");
  const [userList, setUserList] = useState([]);
  const [searParams, setSearParams] = useState<any>({});
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  useEffect(() => {
    loadUser();
    const searchObj: any = {};
    searchObj["startTime"] =
      moment().format("YYYY-MM").toString() + "-01 00:00:00";
    searchObj["endTime"] =
      moment().format("YYYY-MM-DD").toString() + " 23:59:59";
    setSearParams(searchObj);
  }, []);
  useEffect(() => {
    loadData();
  }, [pager]);
  const selectUser = (value: string) => {
    setUserId(value);
  };
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const onFinish = (values: any) => {
    const searchObj: any = values;
    const rangeTime = values["rangeTime"];
    if (rangeTime && rangeTime.length === 2) {
      searchObj["startTime"] =
        rangeTime[0].format("YYYY-MM-DD").toString() + " 00:00:00";
      searchObj["endTime"] =
        rangeTime[1].format("YYYY-MM-DD").toString() + " 23:59:59";
    }
    searchObj["rangeTime"] = "";
    setSearParams(searchObj);
    const tmpPager = JSON.parse(JSON.stringify(pager));
    tmpPager.pageNo = 1;
    setPager(tmpPager);
  };
  const columns = [
    {
      title: "姓名",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "处理单数",
      dataIndex: "orderNum",
      key: "orderNum",
    },
    {
      title: "总运费",
      dataIndex: "freightSum",
      key: "freightSum",
      render: (_: any, record: any) => (
        <span className="red-price">{record.freightSum}</span>
      ),
    },
    {
      title: "线上金额",
      dataIndex: "freightSumOn",
      key: "freightSumOn",
      render: (_: any, record: any) =>
        record.freightCountOn <= 0 ? (
          "-"
        ) : (
          <>
            <span className="red-price">{record.freightSumOn}</span>
            <span className="ml-1">{record.freightCountOn}单</span>
          </>
        ),
    },
    {
      title: "线下金额",
      dataIndex: "freightSumUn",
      key: "freightSumUn",
      render: (_: any, record: any) =>
        record.freightCountUn <= 0 ? (
          "-"
        ) : (
          <>
            <span className="red-price">{record.freightSumUn}</span>
            <span className="ml-1">{record.freightCountUn}单</span>
          </>
        ),
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      render: (_: any, record: any) => (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            setUserId(record.userId);
            setShowOrder(true);
          }}
        >
          查看订单
        </Button>
      ),
    },
  ];
  const loadData = async () => {
    try {
      setLoading(true);
      const params = JSON.parse(JSON.stringify(searParams));
      if (_.isEmpty(searParams["startTime"])) {
        params["startTime"] =
          moment().format("YYYY-MM").toString() + "-01 00:00:00";
        params["endTime"] =
          moment().format("YYYY-MM-DD").toString() + " 23:59:59";
      }
      const res: any = await request.get({
        url: "/hy/employeeJs/statisticsList",
        params: {
          ...pager,
          ...params,
          userId,
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  const loadUser = async () => {
    try {
      const res: any = await request.get({
        url: "/hy/hyEmployee/list",
        params: {
          pageNo: 1,
          pageSize: 9999,
        },
      });
      setUserList(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
  };
  return (
    <div className="container">
      <Form
        form={form}
        layout="inline"
        onFinish={onFinish}
        initialValues={{
          userId: "",
          rangeTime: [moment(moment().format("YYYY-MM") + "-01"), moment()],
        }}
        style={{ marginBottom: "8px" }}
      >
        <Form.Item name="userId" style={{ width: "160px" }}>
          <Select placeholder="请选择员工" onSelect={selectUser}>
            <Option key="" value="">
              请选择员工
            </Option>
            {userList.map((item: any) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="rangeTime">
          <RangePicker format="YYYY-MM-DD" allowClear={false} inputReadOnly />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          current: pager.pageNo,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <NoSettlementOrder
          userId={userId}
          startTime={searParams?.startTime ?? ""}
          endTime={searParams?.endTime ?? ""}
          open={showOrder}
          refresh={() => {
            if (pager.pageNo !== 1) {
              const tpager = JSON.parse(JSON.stringify(pager));
              tpager.pageNo = 1;
              setPager(tpager);
            } else {
              loadData();
            }
          }}
          onCancel={() => {
            setShowOrder(false);
          }}
        />
      </Suspense>
    </div>
  );
};

export default Index;
