import React, { ReactNode } from "react";
import _ from "lodash";
import { Button, Popconfirm, Tag, TagType } from "antd";

const Index: React.FC<any> = (props: any) => {
  return (
    <div>
      {props.hyOrder.ptType !== "1" ? (
        "-"
      ) : (
        <div>
          <span className="text-price text-red-500">
            {props.hyOrder.ptPrice}
          </span>
          <span>（{props.hyOrder.relateName}）</span>
          <div>
            {getStatusName(props.hyOrder)}
            <div className="mt-1">
              {props.hyOrder.ptFinanceStatus === "1" && props.confirmPtMoney && (
                <Popconfirm
                  placement="topRight"
                  title="确认已收到跑腿费吗？"
                  onConfirm={props.confirmPtMoney}
                  okText="确定"
                  cancelText="取消"
                >
                  <Button type="primary" size="small">
                    确认跑腿费
                  </Button>
                </Popconfirm>
              )}
              {props.hyOrder.ptFinanceStatus === "2" && <Tag>跑腿费已确认</Tag>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;

const getStatusName = (hyOrder: any): ReactNode => {
  if (hyOrder.ptStatus === "1") {
    return <Tag color="red">待支付</Tag>;
  }
  if (hyOrder.ptStatus === "2") {
    return <Tag color="green">线下支付</Tag>;
  }
  if (hyOrder.ptStatus === "3") {
    return <Tag color="blue">线上支付</Tag>;
  }
  if (hyOrder.ptStatus === "4") {
    return <Tag color="green">线下支付已结算</Tag>;
  }
  if (hyOrder.ptStatus === "5") {
    return <Tag color="blue">线上支付已结算</Tag>;
  }
  return "";
};
