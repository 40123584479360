import React, { Suspense, useEffect, useState, lazy } from "react";
import request from "@/service";
import { Button, Form, Select, Spin, Table, DatePicker } from "antd";
import moment from "moment";
import _ from "lodash";

const SettlementPc = lazy(() => import("./SettlementPc"));
const { Option } = Select;

const Index: React.FC<any> = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showPc, setShowPc] = useState(false);
  const [userId, setUserId] = useState("");
  const [userList, setUserList] = useState([]);
  const [searParams, setSearParams] = useState<any>({});
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  useEffect(() => {
    loadUser();
    const searchObj: any = {};
    searchObj["month"] = moment().format("YYYY-MM").toString();
    setSearParams(searchObj);
  }, []);
  useEffect(() => {
    loadData();
  }, [pager]);
  const selectUser = (value: string) => {
    setUserId(value);
  };
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const onFinish = (values: any) => {
    const searchObj: any = values;
    setSearParams(searchObj);
    const tmpPager = JSON.parse(JSON.stringify(pager));
    tmpPager.pageNo = 1;
    setPager(tmpPager);
  };
  const columns = [
    {
      title: "姓名",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "批次数",
      dataIndex: "pcNum",
      key: "pcNum",
    },
    {
      title: "订单数",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "总金额",
      dataIndex: "money",
      key: "money",
      render: (_: any, record: any) => (
        <span className="red-price">{record.money}</span>
      ),
    },
    {
      title: "线上金额",
      dataIndex: "onlineMoney",
      key: "onlineMoney",
      render: (_: any, record: any) =>
        record.onlineCount <= 0 ? (
          "-"
        ) : (
          <>
            <span className="red-price">{record.onlineMoney}</span>
            <span className="ml-1">({record.onlineCount}单)</span>
          </>
        ),
    },
    {
      title: "线下金额",
      dataIndex: "offlineMoney",
      key: "offlineMoney",
      render: (_: any, record: any) =>
        record.offlineCount <= 0 ? (
          "-"
        ) : (
          <>
            <span className="red-price">{record.offlineMoney}</span>
            <span className="ml-1">({record.offlineCount}单)</span>
          </>
        ),
    },
    {
      title: "佣金",
      dataIndex: "sxMoney",
      key: "sxMoney",
      render: (_: any, record: any) => (
        <span className="red-price">{record.sxMoney}</span>
      ),
    },
    {
      title: "到账金额",
      dataIndex: "dzMoney",
      key: "dzMoney",
      render: (_: any, record: any) => (
        <span className="red-price">{record.dzMoney}</span>
      ),
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      render: (_: any, record: any) => (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            setUserId(record.toUserId);
            setShowPc(true);
          }}
        >
          结算明细
        </Button>
      ),
    },
  ];
  const loadData = async () => {
    try {
      setLoading(true);
      const params = JSON.parse(JSON.stringify(searParams));
      if (_.isEmpty(searParams["month"])) {
        params["month"] = moment().format("YYYY-MM").toString();
      }
      const res: any = await request.get({
        url: "/hy/employeeJs/jsList",
        params: {
          ...pager,
          ...params,
          userId,
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  const loadUser = async () => {
    try {
      const res: any = await request.get({
        url: "/hy/hyEmployee/list",
        params: {
          pageNo: 1,
          pageSize: 9999,
        },
      });
      setUserList(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
  };
  return (
    <div className="container">
      <Form
        form={form}
        layout="inline"
        onFinish={onFinish}
        initialValues={{
          userId: "",
          month: moment(moment().format("YYYY-MM").toString()),
        }}
        style={{ marginBottom: "8px" }}
      >
        <Form.Item name="userId" style={{ width: "160px" }}>
          <Select placeholder="请选择员工" onSelect={selectUser}>
            <Option key="" value="">
              请选择员工
            </Option>
            {userList.map((item: any) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="month">
          <DatePicker
            picker="month"
            format="YYYY-MM"
            allowClear={false}
            inputReadOnly
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          current: pager.pageNo,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <SettlementPc
          userId={userId}
          month={searParams.month}
          open={showPc}
          onCancel={() => {
            setShowPc(false);
          }}
        />
      </Suspense>
    </div>
  );
};

export default Index;
