import { Tabs } from "antd";
import React from "react";
import CustTxPanel from "./CustTxPanel";
import CustAccountPanel from "./CustAccountPanel";
import CustRegistInfoPanel from "./CustRegistInfoPanel";

const Index = () => {
  const items = [
    { label: "提现记录", key: "1", children: <CustTxPanel /> }, // 务必填写 key
    { label: "账户信息", key: "2", children: <CustAccountPanel /> },
    { label: "注册信息", key: "3", children: <CustRegistInfoPanel /> },
  ];
  return (
    <div className="container h-full">
      <div className="bg-white">
        <Tabs items={items} defaultActiveKey="1" type="card" />
      </div>
    </div>
  );
};
export default Index;
