import React, { Suspense, useEffect, useState, lazy } from "react";
import request from "@/service";
import { Button, Form, Select, Spin, Table, DatePicker } from "antd";
import moment from "moment";
import _ from "lodash";

const StatisticsDay = lazy(() => import("./StatisticsDay"));
const { Option } = Select;

const Index: React.FC<any> = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showDay, setShowDay] = useState(false);
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [userList, setUserList] = useState([]);
  const [searParams, setSearParams] = useState<any>({});
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  useEffect(() => {
    loadUser();
    const searchObj: any = {};
    searchObj["month"] = moment().format("YYYY-MM").toString();
    setSearParams(searchObj);
  }, []);
  useEffect(() => {
    loadData();
  }, [pager]);
  const selectUser = (value: string) => {
    if (!_.isEmpty(value)) {
      setUserId(value.split("_")[0]);
      setUserName(value.split("_")[1]);
    } else {
      setUserId("");
      setUserName("");
    }
  };
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const onFinish = (values: any) => {
    const mon = moment(values["month"]).format("YYYY-MM").toString();
    const searchObj: any = { month: mon };
    setSearParams(searchObj);
    const tmpPager = JSON.parse(JSON.stringify(pager));
    tmpPager.pageNo = 1;
    setPager(tmpPager);
  };
  const columns = [
    {
      title: "姓名",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "处理单数",
      dataIndex: "orderNum",
      key: "orderNum",
      render: (_: any, record: any) =>
        record.orderNum <= 0 ? "-" : record.orderNum,
    },
    {
      title: "线上运费",
      dataIndex: "freightCountOn",
      key: "freightCountOn",
      render: (_: any, record: any) =>
        record.freightCountOn <= 0 ? (
          "-"
        ) : (
          <>
            <span className="red-price">{record.freightSumOn}</span>
            <span className="ml-1">({record.freightCountOn}单)</span>
          </>
        ),
    },
    {
      title: "线下运费",
      dataIndex: "freightCountUn",
      key: "freightCountUn",
      render: (_: any, record: any) =>
        record.freightCountUn <= 0 ? (
          "-"
        ) : (
          <>
            <span className="red-price">{record.freightSumUn}</span>
            <span className="ml-1">({record.freightCountUn}单)</span>
            {record.freightYueCount > 0 && (
              <>
                <span>(包月：</span>
                <span className="text-price">{record.freightYueSum}</span>
                <span>{record.freightYueCount}单)</span>
              </>
            )}
            {record.freightQuanCount > 0 && (
              <>
                <span>(运费券：</span>
                <span className="text-price">{record.freightQuanSum}</span>
                <span>{record.freightQuanCount}单)</span>
              </>
            )}
          </>
        ),
    },
    {
      title: "线上代收",
      dataIndex: "orderOnNum",
      key: "orderOnNum",
      render: (_: any, record: any) =>
        record.orderOnNum <= 0 ? (
          "-"
        ) : (
          <>
            <span className="red-price">{record.orderMoneyOn}</span>
            <span className="ml-1">({record.orderOnNum}单)</span>
          </>
        ),
    },
    {
      title: "线下代收",
      dataIndex: "orderUnNum",
      key: "orderUnNum",
      render: (_: any, record: any) =>
        record.orderUnNum <= 0 ? (
          "-"
        ) : (
          <>
            <span className="red-price">{record.orderMoneyUn}</span>
            <span className="ml-1">({record.orderUnNum}单)</span>
          </>
        ),
    },
    {
      title: "线上跑腿费",
      dataIndex: "ptCountOn",
      key: "ptCountOn",
      render: (_: any, record: any) =>
        record.ptCountOn <= 0 ? (
          "-"
        ) : (
          <>
            <span className="red-price">{record.ptMoneyOn}</span>
            <span className="ml-1">({record.ptCountOn}单)</span>
          </>
        ),
    },
    {
      title: "线下跑腿费",
      dataIndex: "ptCountUn",
      key: "ptCountUn",
      render: (_: any, record: any) =>
        record.ptCountUn <= 0 ? (
          "-"
        ) : (
          <>
            <span className="red-price">{record.ptMoneyUn}</span>
            <span className="ml-1">({record.ptCountUn}单)</span>
          </>
        ),
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      render: (_: any, record: any) => (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            setUserId(record.userId);
            setUserName(record.userName);
            setShowDay(true);
          }}
        >
          按天查看
        </Button>
      ),
    },
  ];
  const loadData = async () => {
    try {
      setLoading(true);
      const params = JSON.parse(JSON.stringify(searParams));
      if (_.isEmpty(searParams["month"])) {
        params["month"] = moment().format("YYYY-MM").toString();
      }
      const res: any = await request.get({
        url: "/hy/hyStatistics/list",
        params: {
          ...pager,
          ...params,
          tjType: "2",
          tjUserType: "2",
          userId,
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  const loadUser = async () => {
    try {
      const res: any = await request.get({
        url: "/hy/hyEmployee/list",
        params: {
          pageNo: 1,
          pageSize: 9999,
        },
      });
      setUserList(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
  };
  return (
    <div className="container">
      <Form
        form={form}
        layout="inline"
        onFinish={onFinish}
        initialValues={{
          userId: "",
          month: moment(moment().format("YYYY-MM").toString()),
        }}
        style={{ marginBottom: "8px" }}
      >
        <Form.Item name="userId" style={{ width: "160px" }}>
          <Select placeholder="请选择员工" onSelect={selectUser}>
            <Option key="" value="">
              请选择员工
            </Option>
            {userList.map((item: any) => {
              return (
                <Option key={item.id} value={item.id + "_" + item.name}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="month">
          <DatePicker
            picker="month"
            format="YYYY-MM"
            style={{ width: "160px" }}
            allowClear={false}
            inputReadOnly
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="search-btn-bg">
            查询
          </Button>
        </Form.Item>
      </Form>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="userId"
        dataSource={tableData}
        pagination={{
          current: pager.pageNo,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <StatisticsDay
          title={userName + "【" + searParams.month + "】"}
          userId={userId}
          month={searParams.month}
          open={showDay}
          onCancel={() => {
            setShowDay(false);
          }}
        />
      </Suspense>
    </div>
  );
};

export default Index;
