import SingleSearch from "@/components/SingleSearch";
import request from "@/service";
import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm, Space, Spin, Table, Tag } from "antd";
import qs from "qs";
import _ from "lodash";
import React, { Suspense, useEffect, useState, lazy } from "react";

const AddAccount = lazy(() => import("./AddAccount"));
const EditAccount = lazy(() => import("./EditAccount"));

const HyAddress = () => {
  const [curId, setCurId] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  let _keyWords = "";
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const refresh = () => {
    setShowAdd(false);
    setShowEdit(false);
    loadData();
  };
  const columns = [
    {
      title: "公司名称",
      dataIndex: "company",
      width: 160,
      key: "company",
    },
    {
      title: "开户人姓名",
      dataIndex: "payAccountName",
      key: "payAccountName",
    },
    {
      title: "开户行",
      dataIndex: "payBankName",
      key: "payBankName",
    },
    {
      title: "银行账号",
      dataIndex: "payAccountNo",
      key: "payAccountNo",
    },
    {
      title: "开户行省市",
      dataIndex: "payArea",
      key: "payArea",
      ellipsis: true,
      render: (_: any, record: any) => (
        <span>
          {record.payProvinceName}
          {record.payCityName}
        </span>
      ),
    },
    {
      title: "手机号",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      render: (_: any, record: any) => (
        <Space size="small">
          <Button
            type="primary"
            size="small"
            title="编辑"
            icon={<FormOutlined />}
            onClick={() => clickEdit(record.id)}
          />
          <Popconfirm
            title="确定要删除账户吗？"
            placement="topRight"
            onConfirm={() => deleteAccount(record.id)}
          >
            <Button
              type="primary"
              danger
              size="small"
              title="删除"
              loading={delLoading}
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const clickEdit = (id: any) => {
    setCurId(id);
    setShowEdit(true);
  };
  const doSearch = (keyWords: string) => {
    _keyWords = keyWords;
    loadData();
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/hyUserAccount/list",
        params: {
          ...pager,
          keyWords: _keyWords,
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  const deleteAccount = async (id: string) => {
    try {
      setDelLoading(true);
      const res: any = await request.post({
        url: "/hy/hyUserAccount/update",
        data: qs.stringify({
          id,
          delFlag: "1",
        }),
      });
      message.success("删除成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
    setDelLoading(false);
  };
  return (
    <div className="container">
      <div className="flex mb-2">
        <SingleSearch placeholder="公司名称、手机号等" doSearch={doSearch} />
        <Button type="primary" onClick={() => setShowAdd(true)}>
          新建
        </Button>
      </div>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <AddAccount
          open={showAdd}
          refresh={refresh}
          onCancel={() => {
            setShowAdd(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <EditAccount
          addressId={curId}
          open={showEdit}
          refresh={refresh}
          onCancel={() => {
            setShowEdit(false);
          }}
        />
      </Suspense>
    </div>
  );
};

export default HyAddress;
