import React from "react";
import _ from "lodash";

const Index: React.FC<any> = (props: any) => {
  let showCompany = false;
  if (
    !_.isEmpty(props.hyOrder.rcvCompany) ||
    !_.isEmpty(props.hyOrder.rcvCompany)
  ) {
    showCompany = true;
  }
  return (
    <div>
      {showCompany && (
        <div>
          {!_.isEmpty(props.hyOrder.rcvCompany) && (
            <span>{props.hyOrder.rcvCompany}</span>
          )}
          {!_.isEmpty(props.hyOrder.rcvLine) && (
            <span>({props.hyOrder.rcvLine})</span>
          )}
        </div>
      )}
      <div>
        <span>{props.hyOrder.rcvUserName}</span>
        <span>{props.hyOrder.rcvPhone}</span>
      </div>
      <div>
        <span>{props.hyOrder.rcvProvince}</span>
        <span>{props.hyOrder.rcvCity}</span>
        <span>{props.hyOrder.rcvArea}</span>
        <span>{props.hyOrder.rcvAddress}</span>
      </div>
    </div>
  );
};

export default Index;
