import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import storageUtil from "@/utils/storage";
import _ from "lodash";
import NoPermission from "@/pages/NoPermission";
import Loading from "@/pages/Loading";

const Index: React.FC<any> = (props) => {
  const location = useLocation();
  let pathArr: string[] = [];
  const [status, setStatus] = useState("0");
  const menusStr = storageUtil.getItem("pathArr");
  if (!_.isEmpty(menusStr)) {
    pathArr = menusStr.split(",");
  }
  // console.log("menus-->", pathArr);
  useEffect(() => {
    // console.log("Auth useEffect-->", location.pathname);
    if (_.includes(pathArr, location.pathname)) {
      setStatus("1");
    } else {
      setStatus("2");
    }
  }, [location]);

  return (
    <>
      {status === "0" ? (
        <Loading />
      ) : (
        <>{status === "1" ? props.children : <NoPermission />}</>
      )}
    </>
  );
};

export default Index;
