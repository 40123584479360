import request from "@/service";
import { Button, message, Popconfirm, Space, Spin, Table } from "antd";
import qs from "qs";
import _ from "lodash";
import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  PlayCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";

const AddJob = lazy(() => import("./AddJob"));
const EditJob = lazy(() => import("./EditJob"));

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [curId, setCurId] = useState("");
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "任务名称",
      dataIndex: "jobName",
      key: "jobName",
    },
    {
      title: "任务分组",
      dataIndex: "jobGroup",
      key: "jobGroup",
    },
    {
      title: "任务类",
      dataIndex: "beanClass",
      key: "beanClass",
    },
    {
      title: "cron表达式",
      dataIndex: "cronExpression",
      key: "cronExpression",
    },
    // {
    //   title: "方法名称",
    //   dataIndex: "methodName",
    //   key: "methodName",
    // },
    // {
    //   title: "任务是否有状态",
    //   dataIndex: "isConcurrent",
    //   key: "isConcurrent",
    // },
    {
      title: "任务描述",
      dataIndex: "description",
      key: "description",
    },
    // {
    //   title: "创建时间",
    //   dataIndex: "createDate",
    //   key: "createDate",
    // },
    // {
    //   title: "Spring bean",
    //   dataIndex: "springBean",
    //   key: "springBean",
    // },
    {
      title: "停起操作",
      dataIndex: "jobStatus",
      key: "jobStatus",
      render: (_: any, record: any) => (
        <>
          {record.jobStatus === "0" && (
            <Popconfirm
              placement="topRight"
              title="确定要开启任务吗？"
              onConfirm={() => {
                changeStatus(record.id, "start");
              }}
              okText="确定"
              cancelText="取消"
            >
              <Button type="primary" size="small" icon={<PlayCircleOutlined />}>
                开启
              </Button>
            </Popconfirm>
          )}
          {record.jobStatus === "1" && (
            <Popconfirm
              placement="topRight"
              title="确定要停止任务吗？"
              onConfirm={() => {
                changeStatus(record.id, "stop");
              }}
              okText="确定"
              cancelText="取消"
            >
              <Button
                type="primary"
                size="small"
                danger
                icon={<StopOutlined />}
              >
                停止
              </Button>
            </Popconfirm>
          )}
        </>
      ),
    },
    {
      title: "操作",
      dataIndex: "ID",
      key: "ID",
      render: (_: any, record: any) => (
        <Space size="small">
          <Button
            type="primary"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              if (record.jobStatus === "1") {
                message.error("请先停止任务！");
              } else {
                setCurId(record.id);
                setShowEdit(true);
              }
            }}
          />
          <Popconfirm
            placement="topRight"
            title="确定要删除任务吗？"
            onConfirm={() => {
              deleteJob(record.id);
            }}
            okText="确定"
            cancelText="取消"
          >
            <Button
              type="primary"
              size="small"
              danger
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const refresh = () => {
    setShowAdd(false);
    setShowEdit(false);
    loadData();
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/common/job/list",
        params: {
          ...pager,
        },
      });
      setTotal(res?.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  const changeStatus = async (id: string, cmd: string) => {
    try {
      const res: any = await request.post({
        url: "/common/job/changeJobStatus",
        data: qs.stringify({
          id,
          cmd,
        }),
      });
      message.success("删除成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
  };
  const deleteJob = async (id: string) => {
    try {
      const res: any = await request.post({
        url: "/common/job/remove",
        data: qs.stringify({
          id,
        }),
      });
      message.success("删除成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
  };
  return (
    <div className="container">
      <div className="mb-1.5">
        <Button
          type="primary"
          onClick={() => {
            setShowAdd(true);
          }}
        >
          添加任务
        </Button>
      </div>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="createTime"
        dataSource={tableData}
        pagination={{
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <AddJob
          open={showAdd}
          refresh={refresh}
          onCancel={() => {
            setShowAdd(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <EditJob
          jobId={curId}
          open={showEdit}
          refresh={refresh}
          onCancel={() => {
            setShowEdit(false);
          }}
        />
      </Suspense>
    </div>
  );
};

export default Index;
