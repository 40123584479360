import { HashRouter as Router, Routes, Route } from "react-router-dom";
import React, { lazy } from "react";
import App from "@/pages/App";
import NotFound from "@/pages/NotFound";
import Login from "@/pages/Login";
import Home from "@/pages/Home";
import Auth from "@/components/Auth";
import AddressImage from "@/components/AddressImage";
import HyEmployee from "@/pages/hyEmployee";
import HySite from "@/pages/hySite";
import HyOrgLine from "@/pages/hyOrgLine";
import HyOrder from "@/pages/hyOrder";
import HyPaiOrder from "@/pages/hyPaiOrder";
import FinanceConfirm from "@/pages/financeConfirm";
import EmployeeJs from "@/pages/employeeJs";
import HyAddress from "@/pages/hyAddress";
import HyUserAccount from "@/pages/hyUserAccount";
import HyPayment from "@/pages/hyPayment";
import HyStatistics from "@/pages/hyStatistics";
import HyTx from "@/pages/hyTx";
/***************下面部分是自寄客户菜单了***********************************/
import CustSendOrder from "@/pages/cust/custSendOrder";
import CustRcvOrder from "@/pages/cust/custRcvOrder";
import CustSite from "@/pages/cust/custSite";
import CustAddress from "@/pages/cust/custAddress";
import CustChild from "@/pages/cust/custChild";
import CustTx from "@/pages/cust/custTx";
import CustJs from "@/pages/cust/custJs";
/**************下面部分是客服菜单了***********************************/
import HyKefu from "@/pages/kefu/hyKefu";
import HyWuliu from "@/pages/kefu/hyWuliu";
import HyCust from "@/pages/kefu/hyCust";
import SmsSend from "@/pages/kefu/smsSend";
import LoginLogs from "@/pages/kefu/loginLogs";
import SysStatistics from "@/pages/kefu/sysstatistics";
import HyWatch from "@/pages/kefu/hyWatch";
import HyCancel from "@/pages/kefu/hyCancel";
import ShareAddress from "@/pages/kefu/shareAddress";
import HyOrderJS from "@/pages/kefu/hyOrderJS";
import UserTx from "@/pages/kefu/userTx";
import WxminiPic from "@/pages/kefu/wxminiPic";
import SysRole from "@/pages/kefu/sysrole";
import SysMenu from "@/pages/kefu/sysmenu";
import Online from "@/pages/kefu/online";
import Druid from "@/pages/kefu/druid";
import CodeGenerator from "@/pages/kefu/codegenerator";
import SysJob from "@/pages/kefu/sysjob";
import SysLog from "@/pages/kefu/syslog";
import Bank from "@/pages/kefu/bank";

const MyRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="" element={<Home />}></Route>
          <Route
            path="hyEmployee"
            element={
              <Auth>
                <HyEmployee />
              </Auth>
            }
          ></Route>
          <Route
            path="hySite"
            element={
              <Auth>
                <HySite />
              </Auth>
            }
          ></Route>
          <Route
            path="hyOrgLine"
            element={
              <Auth>
                <HyOrgLine />
              </Auth>
            }
          ></Route>
          <Route
            path="hyOrder"
            element={
              <Auth>
                <HyOrder />
              </Auth>
            }
          ></Route>
          <Route
            path="hyPaiOrder"
            element={
              <Auth>
                <HyPaiOrder />
              </Auth>
            }
          ></Route>
          <Route
            path="financeConfirm"
            element={
              <Auth>
                <FinanceConfirm />
              </Auth>
            }
          ></Route>
          <Route
            path="employeeJs"
            element={
              <Auth>
                <EmployeeJs />
              </Auth>
            }
          ></Route>
          <Route
            path="hyAddress"
            element={
              <Auth>
                <HyAddress />
              </Auth>
            }
          ></Route>
          <Route
            path="hyUserAccount"
            element={
              <Auth>
                <HyUserAccount />
              </Auth>
            }
          ></Route>
          <Route
            path="hyPayment"
            element={
              <Auth>
                <HyPayment />
              </Auth>
            }
          ></Route>
          <Route
            path="hyStatistics"
            element={
              <Auth>
                <HyStatistics />
              </Auth>
            }
          ></Route>
          <Route
            path="hyTx"
            element={
              <Auth>
                <HyTx />
              </Auth>
            }
          ></Route>
          {/***************下面部分是自寄客户菜单了***********************************/}
          <Route
            path="custSendOrder"
            element={
              <Auth>
                <CustSendOrder />
              </Auth>
            }
          ></Route>
          <Route
            path="custRcvOrder"
            element={
              <Auth>
                <CustRcvOrder />
              </Auth>
            }
          ></Route>
          <Route
            path="custSite"
            element={
              <Auth>
                <CustSite />
              </Auth>
            }
          ></Route>
          <Route
            path="custAddress"
            element={
              <Auth>
                <CustAddress />
              </Auth>
            }
          ></Route>
          <Route
            path="custChild"
            element={
              <Auth>
                <CustChild />
              </Auth>
            }
          ></Route>
          <Route
            path="custTx"
            element={
              <Auth>
                <CustTx />
              </Auth>
            }
          ></Route>
          <Route
            path="custJs"
            element={
              <Auth>
                <CustJs />
              </Auth>
            }
          ></Route>
          {/***************下面部分是客服菜单了***********************************/}
          <Route
            path="hyKefu"
            element={
              <Auth>
                <HyKefu />
              </Auth>
            }
          />
          <Route
            path="hyWuliu"
            element={
              <Auth>
                <HyWuliu />
              </Auth>
            }
          />
          <Route
            path="hyCust"
            element={
              <Auth>
                <HyCust />
              </Auth>
            }
          />
          <Route
            path="smsSend"
            element={
              <Auth>
                <SmsSend />
              </Auth>
            }
          />
          <Route
            path="loginLogs"
            element={
              <Auth>
                <LoginLogs />
              </Auth>
            }
          />
          <Route
            path="sysstatistics"
            element={
              <Auth>
                <SysStatistics />
              </Auth>
            }
          />
          <Route
            path="hyWatch"
            element={
              <Auth>
                <HyWatch />
              </Auth>
            }
          />
          <Route
            path="hyCancel"
            element={
              <Auth>
                <HyCancel />
              </Auth>
            }
          />
          <Route
            path="shareAddress"
            element={
              <Auth>
                <ShareAddress />
              </Auth>
            }
          />
          <Route
            path="hyOrderJS"
            element={
              <Auth>
                <HyOrderJS />
              </Auth>
            }
          />
          <Route
            path="userTx"
            element={
              <Auth>
                <UserTx />
              </Auth>
            }
          />
          <Route
            path="Bank"
            element={
              <Auth>
                <Bank />
              </Auth>
            }
          />
          <Route
            path="wxminiPic"
            element={
              <Auth>
                <WxminiPic />
              </Auth>
            }
          />
          <Route
            path="sysrole"
            element={
              <Auth>
                <SysRole />
              </Auth>
            }
          />
          <Route
            path="sysmenu"
            element={
              <Auth>
                <SysMenu />
              </Auth>
            }
          />
          <Route
            path="druid"
            element={
              <Auth>
                <Druid />
              </Auth>
            }
          />
          <Route
            path="codegenerator"
            element={
              <Auth>
                <CodeGenerator />
              </Auth>
            }
          />
          <Route
            path="sysjob"
            element={
              <Auth>
                <SysJob />
              </Auth>
            }
          />
          <Route
            path="syslog"
            element={
              <Auth>
                <SysLog />
              </Auth>
            }
          />
          <Route
            path="online"
            element={
              <Auth>
                <Online />
              </Auth>
            }
          />
        </Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/addressImage" element={<AddressImage />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </Router>
  );
};

export default MyRouter;
