import React, { useEffect, useState, lazy, Suspense } from "react";
import request from "@/service";
import { Button, Card, Spin, Table, Tag } from "antd";
import _ from "lodash";

const TxNotice = lazy(() => import("./TxNotice"));
const TxBack = lazy(() => import("./TxBack"));

const Index: React.FC<any> = () => {
  const [showTxNotice, setShowTxNotice] = useState(false);
  const [showTxBack, setShowTxBack] = useState(false);
  const [txId, setTxId] = useState("");
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  useEffect(() => {
    loadBalance();
  }, []);
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const txBack = (txId: string) => {
    setShowTxNotice(false);
    setShowTxBack(true);
    setTxId(txId);
  };
  const columns = [
    {
      title: "单号",
      dataIndex: "txNo",
      key: "txNo",
    },
    {
      title: "申请人",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "提现金额",
      dataIndex: "txMoney",
      key: "txMoney",
      render: (_: any, record: any) => (
        <span className="red-price">{record.txMoney}</span>
      ),
    },
    {
      title: "手续费",
      dataIndex: "sxMoney",
      key: "sxMoney",
      render: (_: any, record: any) =>
        record.sxMoney === 0 ? (
          "0"
        ) : (
          <span className="green-price">-{record.sxMoney}</span>
        ),
    },
    {
      title: "到账金额",
      dataIndex: "dzMoney",
      key: "dzMoney",
      render: (_: any, record: any) => (
        <span className="red-price">{record.dzMoney}</span>
      ),
    },
    {
      title: "持卡人",
      dataIndex: "cardName",
      key: "cardName",
    },
    {
      title: "银行卡号",
      dataIndex: "cardNo",
      key: "cardNo",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (_: any, record: any) =>
        record.doStatus === "0" ? (
          <Tag color="green">申请中</Tag>
        ) : record.doStatus === "1" ? (
          <Tag color="blue">已完成</Tag>
        ) : (
          "-"
        ),
    },
    {
      title: "申请日期",
      dataIndex: "createDate",
      key: "createDate",
    },
  ];
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/freightTx/txList",
        params: {
          ...pager,
        },
      });
      setTotal(res.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  const loadBalance = async () => {
    try {
      setBalanceLoading(true);
      const res: any = await request.get({
        url: "/hy/freightTx/getAccountInfo",
        params: {},
      });
      setBalance(res?.result?.balance ?? 0);
    } catch (err) {
      console.log("err-->", err);
    }
    setBalanceLoading(false);
  };
  return (
    <div className="container">
      <Card
        className="mb-10"
        style={{
          marginTop: "-6px",
          marginBottom: "12px",
          marginLeft: "12px",
          marginRight: "12px",
          borderRadius: "12px",
          backgroundColor: "#d9edf7",
          borderColor: "#d9edf7",
        }}
        bodyStyle={{
          padding: "14px 24px",
        }}
        title=""
      >
        <Spin spinning={balanceLoading}>
          <span className="text-lg">账户余额： </span>
          <span className="text-3xl red-price mr-3">{balance}</span>
          {balance >= 0 && (
            <Button
              type="primary"
              onClick={() => {
                setShowTxNotice(true);
              }}
            >
              提现
            </Button>
          )}
        </Spin>
      </Card>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          current: pager.pageNo,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <TxNotice
          open={showTxNotice}
          onOk={txBack}
          onCancel={() => {
            setShowTxNotice(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Spin />}>
        <TxBack
          txId={txId}
          open={showTxBack}
          onCancel={() => {
            setShowTxBack(false);
          }}
        />
      </Suspense>
    </div>
  );
};

export default Index;
