import React, { useEffect, useState } from "react";
import request from "@/service";
import { Col, Row, Image, Spin, message } from "antd";
import _ from "lodash";

const Index: React.FC = () => {
  const addressId = window.location.href.split("=")[1];
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [imageList, setImageList] = useState<any>([]);
  useEffect(() => {
    if (_.isEmpty(addressId)) {
      message.error("地址ID不能为空！");
      return;
    }
    loadData();
  }, []);
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/hyAddress/getImageList",
        params: { addressId: addressId },
      });
      const arr: any = [];
      if (res.result && res.result.length > 0) {
        res.result.forEach((item: any) => {
          arr.push({
            id: item.id,
            url:
              "http://hy.chehe180.com/chhy/image/showImg?type=s&id=" + item.id,
          });
        });
      }
      setImageList(arr);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  return (
    <Spin spinning={loading}>
      <div
        style={{ height: "100vh", padding: "20px", backgroundColor: "f9f9f9" }}
      >
        <Row gutter={[16, 24]}>
          {imageList.map((item: any) => {
            return (
              <Col span={6} key={item.id}>
                <Image
                  preview={{ visible: false }}
                  width={200}
                  height={200}
                  src={item.url}
                  onClick={() => setVisible(true)}
                />
              </Col>
            );
          })}
        </Row>
        <div style={{ display: "none" }}>
          <Image.PreviewGroup
            preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
          >
            {imageList.map((item: any) => {
              return (
                <React.Fragment key={item.id}>
                  <Image src={item.url} />
                </React.Fragment>
              );
            })}
          </Image.PreviewGroup>
        </div>
      </div>
    </Spin>
  );
};

export default Index;
function setVisible(arg0: boolean) {
  throw new Error("Function not implemented.");
}
