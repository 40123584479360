import React from "react";

const Index: React.FC = () => {
  return (
    <div className="container">
      <a href="http://127.0.0.1:8181/druid/index.html" target="_blank">
        http://127.0.0.1:8181/druid/index.html
      </a>
    </div>
  );
};

export default Index;
