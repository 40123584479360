import request from "@/service";
import { Button, Table } from "antd";
import qs from "qs";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const columns = [
    {
      title: "表名称",
      dataIndex: "tableName",
      key: "tableName",
    },
    {
      title: "表描述",
      dataIndex: "tableComment",
      key: "tableComment",
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "操作",
      dataIndex: "ID",
      key: "ID",
      render: (_: any, record: any) => (
        <>
          <a
            type="primary"
            target="_blank"
            href={
              "http://hy.chehe180.com/chhy/common/generator/code/" +
              record.tableName
            }
          >
            下载
          </a>
        </>
      ),
    },
  ];
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/common/generator/list2",
        params: {
          ...pager,
        },
      });
      setTotal(res?.result?.length ?? 0);
      setTableData(res?.result);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  return (
    <div className="container">
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="createTime"
        dataSource={tableData}
        pagination={{
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
    </div>
  );
};

export default Index;
