import { PictureOutlined } from "@ant-design/icons";
import { Button, Space, Tag } from "antd";
import React from "react";
import _ from "lodash";

const Index: React.FC<any> = (props: any) => {
  return (
    <>
      {!_.isEmpty(props.hyOrder.firstStoreName) && (
        <>
          <div>{props.hyOrder.firstStoreName}</div>
          <div>({props.hyOrder.deptName})</div>
        </>
      )}
      <Space size={2} className="mt-1">
        <Tag style={{ margin: 0 }} color="blue">
          包裹{props.hyOrder.productNumber}
        </Tag>
        {props.hyOrder.imageCount > 0 && props.clickPic && (
          <Button
            type="primary"
            icon={<PictureOutlined />}
            size="small"
            onClick={props.clickPic}
          >
            {props.hyOrder.imageCount}
          </Button>
        )}
      </Space>
    </>
  );
};

export default Index;
