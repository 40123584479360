import request from "@/service";
import { Table } from "antd";
import React, { useEffect, useState } from "react";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const columns = [
    {
      title: "银行名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "银行编码",
      dataIndex: "code",
      key: "code",
    },
  ];
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/bank/list",
        params: {
          ...pager,
        },
      });
      setTotal(res?.total);
      setTableData(res?.rows);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  return (
    <div className="container">
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="code"
        dataSource={tableData}
        pagination={{
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
    </div>
  );
};

export default Index;
