import { Tabs } from "antd";
import React from "react";
import Settlement from "./Settlement";
import NoSettlement from "./NoSettlement";

const Index = () => {
  const items = [
    { label: "未结算", key: "1", children: <NoSettlement /> }, // 务必填写 key
    { label: "已结算", key: "2", children: <Settlement /> },
  ];
  return (
    <div className="container h-full">
      <div className="bg-white">
        <Tabs items={items} defaultActiveKey="1" type="card" />
      </div>
    </div>
  );
};
export default Index;
