/**
 * Storage 二次封装
 */
const NAMESPACE = "chhy";
const storageUtil = {
  setItem(key: string, val: string) {
    const storage = this.getStorage();
    // let obj = { [key]: val }// key是变量的时候需要加上‘[]’，不然就被当做字符串了
    storage[key] = val; // 不能用storage.key(表示一个字符串)
    window.localStorage.setItem(NAMESPACE, JSON.stringify(storage));
  },
  getItem(key: string) {
    return this.getStorage()[key];
  },
  getStorage() {
    const storage = window.localStorage.getItem(NAMESPACE) ?? "{}"; // 给个默认值Object
    return JSON.parse(storage);
  },
  clearItem(key: string) {
    const storage = this.getStorage();
    delete storage.key;
    // 删掉后必须要重新set一遍才能起作用
    window.localStorage.setItem(NAMESPACE, JSON.stringify(storage));
  },
  clear() {
    window.localStorage.clear();
  },
};

export default storageUtil;
