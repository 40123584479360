import SingleSearch from "@/components/SingleSearch";
import request from "@/service";
import {
  PictureOutlined,
  FormOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Button,
  message,
  Modal,
  Popconfirm,
  Space,
  Spin,
  Table,
  Tag,
} from "antd";
import qs from "qs";
import _ from "lodash";
import React, { Suspense, useEffect, useState, lazy } from "react";

const AddWxminiPic = lazy(() => import("./AddWxminiPic"));
const WxminiPicImage = lazy(() => import("./WxminiPicImage"));

const Index = () => {
  const [curId, setCurId] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [delLoading, setDelLoading] = useState(false);
  const [pager, setPager] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  let _keyWords = "";
  useEffect(() => {
    loadData();
  }, [pager]);
  const tableChange = (pagination: any) => {
    setPager({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const refresh = () => {
    setShowAdd(false);
    loadData();
  };

  const columns = [
    {
      title: "标题",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "城市",
      dataIndex: "cityName",
      key: "cityName",
    },
    {
      title: "是否可见",
      dataIndex: "showFlag",
      key: "showFlag",
      render: (_: any, record: any) => (
        <>
          {record.showFlag === "0" && <Tag color="red">否</Tag>}
          {record.showFlag === "1" && <Tag color="green">是</Tag>}
        </>
      ),
    },
    {
      title: "有效期(0代表长期有限)",
      dataIndex: "validDays",
      key: "validDays",
    },
    {
      title: "操作",
      render: (_: any, record: any) => (
        <Space size="small">
          <Button
            type="primary"
            size="small"
            title="编辑"
            icon={<FormOutlined />}
            onClick={() => clickEdit(record)}
          />
          <Popconfirm
            title="确定要删除吗？"
            placement="topRight"
            onConfirm={() => deleteHyad(record.id)}
          >
            <Button
              type="primary"
              danger
              size="small"
              title="删除"
              loading={delLoading}
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const clickEdit = (item: any) => {
    setCurId(item.id);
    setShowAdd(true);
  };
  const clickAdd = () => {
    setCurId("");
    setShowAdd(true);
  };
  const deleteHyad = async (id: string) => {
    try {
      setDelLoading(true);
      const res: any = await request.post({
        url: "/hy/hyAd/remove/" + id,
        data: qs.stringify({}),
      });
      message.success("删除成功！");
      loadData();
    } catch (err) {
      console.log("err-->", err);
    }
    setDelLoading(false);
  };
  const doSearch = (keyWords: string) => {
    _keyWords = keyWords;
    loadData();
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await request.get({
        url: "/hy/hyAd/list",
        params: {
          ...pager,
          keyWords: _keyWords,
        },
      });
      setTotal(res.result.total);
      setTableData(res.result.records);
    } catch (err) {
      console.log("err-->", err);
    }
    setLoading(false);
  };
  return (
    <div className="container">
      <div className="flex mb-2">
        <SingleSearch placeholder="区县编码" doSearch={doSearch} />
        <Button type="primary" onClick={() => clickAdd()}>
          新建
        </Button>
      </div>
      <Table
        size="small"
        tableLayout="auto"
        loading={loading}
        bordered
        rowKey="id"
        dataSource={tableData}
        pagination={{
          total: total,
          showTotal: (t) => {
            return `共${t}条数据`;
          },
          size: "default",
        }}
        columns={columns}
        onChange={tableChange}
      />
      <Suspense fallback={<Spin />}>
        <AddWxminiPic
          open={showAdd}
          orgId={curId}
          refresh={refresh}
          onCancel={() => {
            setShowAdd(false);
          }}
        />
      </Suspense>
    </div>
  );
};

export default Index;
